<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>SHWP</title> -->
    <!-- <link rel="stylesheet" href="node_modules/bootstrap/dist/css/bootstrap.min.css"> -->
    <!-- <link rel="stylesheet" href="style.css"> -->
<!-- </head>
<body> -->
  <div class="">
    <header class="sticky-top">
     <nav class="navbar navbar-expand-lg navbar-dark">
         <div class="container-fluid navbarBar">
           <a class="navbar-brand" href="#">
             {{title}}
           </a>
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
             <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse justify-content-end" id="navbarNav" *ngIf="false">
             <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center justify-content-end w-100">
               <li class="nav-item">
                 <a class="nav-link" href="#scroll2">Stories</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#scroll3">Expertise</a>
               </li>
     
               <li class="nav-item">
                 <a class="nav-link" href="#scroll4">About</a>
               </li>
               <!-- <li class="nav-item">
                 <a class="nav-link">Get Involved</a>
               </li> -->
             </ul>
             <!-- <div class="d-flex align-items-center">
               <a href="#" class="btn donate-btn me-2">Donate</a>
               
             </div> -->
           </div>
         </div>
       </nav>
    </header>
 <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example" tabindex="0">
    <div class="container my-5">
     <h1 class="section-title">1. Introduction</h1>
     
     <div class="content-text mt-3">
       <p class="infm_SbHead">The Animal Vaccine Information Network ({{title}}) is committed to protecting the privacy of its users. 
         This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application. 
         By using our application, you agree to the collection and use of information in accordance with this policy.
         </p>
     </div>
     </div>


       <div class="container my-5" id="scroll2">
         <h1 class="section-title">2. Information We Collect</h1>
         
         <div class="content-text mt-3">
             <!-- <h2>2. Information We Collect</h2> -->
                 <h3 class="infm_SbHead">2.1. We may collect the following information:</h3>

                 <ul class="infm_SbHead informationCollect">
                   <li>Personal data of the User such as, but not limited to, User’s name, 
                      User’s e-mail and contact information.</li>
                   <!-- <li>The User’s tracking Information such as, but not limited to the device ID, Google Advertising ID and Android ID;
                     The User’s data sent across through the Application.</li> -->
                   <!-- <li>As a User of SHWP, you may provide information about yourself, your spouse or family, your friends, their health issues, gender of the patient, age of the patient, previous medication taken, previous medical conditions, allergies, etc.</li> -->
                   <li>The Information specified above and collected by us may be classified as ‘Personal Information’ or ‘Sensitive Information’ under the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. Collection of information which has been designated as ‘sensitive personal data or information’ under the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules requires your express consent. By affirming your assent to this Privacy Policy, you provide your consent to such collection as required under applicable law. Our Services may be unavailable to you in the event such consent through an email is not given.</li>
                   <!-- <li>For Practitioners using SHWP, we would collect your Name, Education, Specialization, address of practicing hospitals/clinics, Contact information, achievements, Device ID (IMEI), Location, etc. If you use SHWP by accessing it through your Google+ and Facebook Profiles and other API’s, we collect such data as well.</li> -->

                 </ul>

                 <h3 class="infm_SbHead">2.2 Personal Information</h3>
                 <p>We may collect personal information, including but not limited to:</p>
                 
             <ul class="infm_SbHead informationCollect">
                <li>Name</li>
                <li>Mobile Number</li>
                <li>Email Address</li>
             </ul>
 
             <!-- <h3 class="infm_SbHead">2.2 Health Information</h3>
             <ul class="informationCollect">
                 <li>Health screenings results</li>
                 <li>Wellness assessments</li>
             </ul>
 
             <h3 class="infm_SbHead">2.3 Usage Data</h3>
             <ul class="informationCollect">
                 <li>Device Information</li>
                 <li>Operating System</li>
                 <li>Pages of our application that you visit</li>
             </ul> -->
         </div>
     </div>


     <div class="container my-5" id="scroll3">
         <h1 class="section-title">3. How We Use Your Information</h1>
         
         <div class="content-text mt-3">
           <p>We use the information we collect for various purposes, including:</p>
            <ul class="infm_SbHead informationCollect">
             <li>To facilitate the registration and login process.</li>
             <!-- <li>To identify and refer students with health and wellness issues to appropriate healthcare providers.</li> -->
             <li>To provide and maintain our application.</li>
             <li>To monitor the usage of our application.</li>
             <li>To inform you about changes to our application.</li>
             <li>To respond to your inquiries and provide customer support.</li>
           </ul>
         </div>
     </div>

     <div class="container my-5">
         <h1 class="section-title">4. Disclosure of Your Information</h1>
         
         <div class="content-text mt-3">
           <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information, except in the following circumstances:</p>
            <ul class="infm_SbHead informationCollect">
              <li>To comply with legal obligations.</li>
              <li>To protect and defend the rights or property of {{title}}.</li>
             <!-- <li>To prevent or investigate possible wrongdoing in connection with the application.</li> -->
             <!-- <li>To prevent or investigate possible wrongdoing in connection with the application.</li> -->
           </ul>
         </div>
     </div>
     

     <div class="container my-5">
         <h1 class="section-title">5. Data Security</h1>
         
         <div class="content-text mt-3">
           <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the Internet, or method of electronic storage, is 100% secure.</p>
         </div>
     </div>

     
     <div class="container my-5">
         <h1 class="section-title">6. Your Rights</h1>
         
         <div class="content-text mt-3">
           <p>You have the right to:</p>
            <ul class="infm_SbHead informationCollect">
             <li>Access the personal information we hold about you.</li>
             <li>Request correction of your personal information.</li>
             <li>Request deletion of your personal information.</li>
            </ul>
            <p>To exercise these rights, please contact us using the information provided below.</p>
         </div>
     </div>

     
     <div class="container my-5" id="scroll4">
         <h1 class="section-title">7. Changes to This Privacy Policy</h1>
         
         <div class="content-text mt-3">
           <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
         </div>
     </div>

     <div class="container my-5">
         <h1 class="section-title">8. Contact Us</h1>
         
         <div class="content-text pb-4 mt-3 mailLink d-flex flex-column">
           <p> If you have any questions about this Privacy Policy, please contact us:</p>
           <div class="d-flex align-items-center">
             <span> Email :</span>
            <a class="ml-3"  href="mailto:shwpsupport@dhspltd.com">evinmohfw@gmail.com</a></div>
            <div class="d-flex align-items-center">
             <span> Website :</span> <a class="ml-3" href="#">{{environmentUrl}}</a></div>
             <!-- <p>Email: [info@SHWP.org]</p> -->
        </div>
     </div>

     <div class="container my-5">
       <h1 class="section-title">9. Account Deletion Request</h1>
       
       <div class="content-text pb-4 mt-3">
         <p>To request the deletion of your account and associated data, please follow these steps.</p>
         <p class="mailLink">Visit our account deletion page: Delete Account - <a class="" href="#">{{environmentUrl}}</a></p>
         <p>Please note that only administrators can log in via the normal web link login, and account deletion is specifically for users who are using the mobile app.</p>
         <p>Follow the prompts to submit your deletion request.</p>
         <p>Data Deletion Details</p>
         <p>When you request account deletion, we will permanently delete the following types of data associated with your account: </p>
         <p>Personal information (e.g., name, email) </p>
         <p>Usage data </p>
         <p>Preferences and settings</p>
       </div>
     </div>
</div>

       <!-- <footer class="footer">
         <div class="container">
             <div class="row">
                 <div class="col-md-4">
                     <h4>Sign Up for Our Newsletter</h4>
                     <p>Stay up to date with our latest stories, resources, and other news from SHWP.</p>
                     <form class="newsletter">
                         <div class="mb-2">
                             <input type="text" class="form-control" placeholder="First Name">
                         </div>
                         <div class="mb-2">
                             <input type="text" class="form-control" placeholder="Last Name">
                         </div>
                         <div class="mb-2">
                             <input type="email" class="form-control" placeholder="Email">
                         </div>
                         <button type="submit" class="btn btn-primary">Sign Up</button>
                     </form>
                 </div>
 
                 <div class="col-md-4">
                     <h4>About</h4>
                     <ul class="footerCol">
                         <li><a>History & Mission</a></li>
                         <li><a>Leadership</a></li>
                         <li><a>Experts</a></li>
                         <li><a>Board of Advisors</a></li>
                         <li><a>Annual Reports</a></li>
                     </ul>
                 </div>
 
                 <div class="col-md-4">
                     <h4>Explore</h4>
                     <ul class="footerCol">
                         <li><a>Stories</a></li>
                         <li><a>Our Expertise</a></li>
                         <li><a>Countries We Support</a></li>
                         <li><a>Technical Resources</a></li>
                         <li><a>Get Involved</a></li>
                     </ul>
                 </div>
             </div>

         </div>
     </footer> -->

 </div>
