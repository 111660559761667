var url = window.origin;
export const environment = {
  production: true,
  rndmTxtLen: 6,
  rndmTxtChars: `!@#$%^&*(){}[]:;"'<>?,./ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`,
  tblPageSize: 50,
  yomSY: 2000,
  astsMinY: 1970,
  lclAPIUrl: 'http://10.10.10.173:8081',
  lndgPathUsr: 'home/setup-users-list',
  lndgPathGnrl: 'home/db-ovrw', // 'home/ovrvw-db',
  lndgPathClnt: url.includes('localhost') ? 'home/e-stk-rprt' : 'home/stk-rprt',
  fetchIP: ['https://geolocation-db.com/json/', 'https://ipv4.jsonip.com/', 'http://api.ipstack.com/202.153.37.82?access_key=0522ce8959bfb2084764c9e8ddcafffa&format=1'], //'https://geolocation-db.com/json/', // 'https://ipv4.jsonip.com/',  // 'http://api.ipify.org/?format=json'
  noPgLen: '&page=pageNo&size=pageSize',
  lclURL: url.includes('localhost'),
  isRprtsHlpShw : true,
  isLclDev: url.includes('localhost') || url.includes('evin-development'),
  isLclDevQa: url.includes('localhost') || url.includes('evin-development') || url.includes('evinqa.dhanushinfotech.com'),
  webURL: url.includes('localhost') ? 'https://training.avinonline.in' : url, // https://evin.dhanushinfotech.com, https://evin-development.dhanushinfotech.com
  isMigURL: url.includes('evinaeonline.in') || url.includes('evinonline.in'),
  isBetaURL: url.includes('evinpreprod.dhanushinfotech.com') || url.includes('evinbeta.dhanushinfotech.com'),
  isLiveQA: url.includes('evinonline.in') || url.includes('evinaeonline.in') || url.includes('https://evinqa.dhanushinfotech.com'),
  lgnDefJWT: `1c6e4b6a879a1661e5014613261de2f1af667440f256b2c7560db2148f55b7fd661490c5c28de9fd73e8980435c7404b726634591c191ddfa175d237b3a38bf3`,
  fsnLicKeyOld: 'qqA3ydjA5C5B3B4D5C4G4C3B9B2D3D2ippE1C3C11d1C7A3B4utcH3B5D7moB-9D3C4D2H-7geE3A3B2F4H2D3H2C8B1E4B1zllA33A8a1B9d1dB-11G4F4D3jyA7RA1E3wwi1A1A7A4B6B5F5D3A2H3C2A5D5C4C7E-11==',
  fsnLicKey: 'yiF3aI-8rA4B8E2C4A4G4D4I3B2B6C8B4E6qg1jI4F2okgF-11hB4E3cufA4B4C2G5G4E3F3A1C8C6D4A3A3B4H3A33qhhD8B1B4hH4B3B2B-32C-22kA3C4E3uwfC5A2B2uomF1B3F1C7B5A4E5E3B4A3H4bddA5E4WD3hlcB5B1E4raB2JF2E1C-21D-16hE1G5D1B2C2D2C5A6D3C3F4A1C1C3v==',
  fcExprtOptns: 'PNG=Export as PNG|JPG=Export as JPG|PDF=Export as PDF', // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
  bugMsg: 'Error occurred while processing your request',
  ssnExpryMsg: 'Session Expired, redirecting to Login page',
  cnfmMsg1: 'You have not allocated fully from older batches. It is highly recommended that you fully allocate from batches that expire first. Continue with your current allocation?',
  TblNoRcrds: 'No Records to display..',
  OrdrFacSlct: 'Select Issuing Facility & Receiving Facility to Choose Products..',
  prntAPIPath: ['/oneanimal-gateway/', '/onehealth-masters/', '/', '/onehealth-assets/', '/onehealth-notifications/','/onehealth-reports/'],
  apiSrcePath: ['onehealth-txns/api/', 'reports/api/', 'api/', '', 'onehealth-reports/api/', 'onehealth-reports/', 'onehealth-assets/api/', 'onehealth-setup/api/', 'onehealth-setup/', 'onehealth-files/', 'onehealth-export/api/', 'onehealth-export/api/', 'evin-manufacturer/api/', 'onehealth-masters/api/'], // `/evin/api/`,
  apiCalls: {
    get: 'Get',
    post: 'Post',
    put: 'Put',
    pat: 'Patch',
    del: 'Delete',
  },
  apiPaths: {
    noPath: '',
    evin: 'onehealth-txns/v1/',
    exprt: 'v1/',
    api: 'api/',
    loginout: 'api/users/v1/',
    chart: 'chart/v1/',
    icatalogue: 'icatalogue/',
    invntry: 'icatalogue/v1/', //'invntry/v1/',
    iCatBtch: 'icatalogue-batch/v1/',
    store: 'store/v1/', // kiosk/v1/',
    trans: 'txn/v1/', //'transaction/v1/',
    trans2: 'txn/v2/',
    manufac: 'manufacturer/v1/',
    bookings: 'bookings/v1/', //order: 'order/v1/'
    activity: 'activity/v1/',
    cargo: 'cargo/v1/', //shipment: 'shipment/v1/',
    tags: 'tag/v1/',
    pranth: 'pranth/v1/',
    product: 'product/v1/',
    producer: 'producer/v1/',
    badge: 'badge/v1/',
    carrier: 'carrier/v1/',
    bookingtracking: 'booking-tracking/v1/',
    txn: 'txn/',
    txnType: 'master-txn-type/v1/',
    mstrSts: 'master-status/v1/',
    ordType: 'master-order-type/v1/',
    cntries: 'master-country/v1/',
    rsnType: 'master-reason-type/v1/',
    states: 'master-state/v1/',
    block: 'master-block/v1/',
    timeZone: 'master-time-zone/v1/',
    language: 'master-language/v1/',
    commuChann: 'master-communication-channel/v1/',
    assetType: 'asset-type/v1/',
    asetDefCnfg: 'asset-default-configuration/v1/',
    booking: 'bookings/',
    getAsetTyp: 'asset-type-monitoring-point/v1/',
    getAsetModl: 'asset-model-sensor-mapping/v1/',
    asetMdl: 'master-asset-model/v1/',
    str: 'store/',
    asetVendor: 'master-asset-vendor/v1/',
    asset: 'asset/v1/',
    asetTypeConfg: 'asset-type-configuration/v1/',
    reportactivity: 'report-activity-user/',
    productstockdeviant: 'icatalogue/',
    asetMonitoringSensor: 'asset-monitoring-point-sensor/v1/',
    mstrRole: 'master-role/v1/',
    rolePrmsn: 'role-permission-configuration/v1/',
    rprtCnsmptn: 'report-consumption/v1/',
    rprtDscrd: 'report-discard/v1/',
    strMappings: 'store-mappings/v1/',
    users: 'users/v1/',
    mstrdst: 'master-district/v1/',
    mstrtm: 'master-tm-type/v1/',
    systemConfiguration: 'system-configuration/v1/',
    file: 'onehealth-files/',
    rprtStkTrnd: 'report-stock-trend/v1/',
    indntBdgeRank: 'store_badge_rank/v1/',
    rprtTrns: 'report-txns/v1/',
    rprtAbnStk: 'report-abnormal-stock/v1/',
    evntNotType: 'master-notification-event-type/v1/',
    ntfcnEvent: 'notification-event/v1/',
    ntfcnEvntDetails: 'notification-event-details/v1/',
    captcha: 'captcha/',
    bdgeRsn: 'badge-reason/v1/',
    webMstrs: 'web-evin-master/v1/',
    lgnMstrs: 'web-login-master/v1/',
    assetMapping: 'asset-mapping/v1/',
    report: 'report/v1/',
    reports: 'reports/v1/',
    cnsmptn: 'consumption/v1/',
    // Added by Ghouse on 15-03-2023
    wastagepath: 'v1/',
    ccp: 'master-store/v1/',
    usrActivity: 'user-activity/v1/',
    txnCnutreports: 'evinae-reports/v1/',
    Cstk:'v1/',
    stkTrnd: 'stocktrends/v1/',
    dscrd: 'discardstock/v1/',
    abnrml: 'abnormal/v1/',
    dscrpncs: 'bookings/v1/',
    dashboard: 'dashboard/v1/',
    notifications: 'notifications/',
    stkAvlblty: 'stock-availability/v1/',
    prnthHierrchy: 'pranth-hierarchy/v1/',
    ManufrMtrlList:'product-producer/v1/',
    vndr: 'vendor/v1/',
    mdl: 'model/v1/',
    prdcrBking: 'producer-booking/v1/',
    cnsgnBking: 'booking-consigne/v1/',
    cnsnBkng: 'consigne-bookings/v1/',
    bkngTracking: 'consignee-booking-tracking/v1/',
    prchsAllcte: 'consigne-bookings-allocate/v1/',
    prchsShip: 'consignee-cargo/v1/',
    asstsErr: 'ctx/v1/', 
    cnsgnSupply: 'consigne-supply/v1/',
    prducrLst: 'producer-txn/',
    mstrPkge: 'master-packages/v1/',
    mstrTrkTyp: 'master-tracking-object-type/v1/'
  },
  apiMthds: {
    login: 'authenticate',
    logout: 'logout', // ?userId=
    genCptch: 'captchaImg',
    reGenCptch: 'reloadCaptchaImg/',
    valdCptch: 'validateCaptcha/capID?captchaAnswer=',
    add: 'add',
    getAll: 'getall',
    create: 'create',
    saveRupdate: 'saveorupdate',
    save: 'save',
    delById: 'deletebyid/',
    delAddById: 'deletebyid/ddd?isSuperAdmin=sss',
    retrveUsrs: 'retrieveusers?isSuperAdmin=sss',
    retrveStrs: 'retrievestores?isSuperAdmin=sss',
    retrveAsts: 'retrieveassets?isSuperAdmin=sss',
    domDoe: 'dom-doe/update-dom-doe',
    dbInvChrt: 'inventory-chart',
    tagCnfg: 'badge-config/',
    getbyModName: '?modulename=',
    invntrySV: 'get-stock-views?page=pageNo&size=pageSize',
    invMatbyStoreId: 'get-products-by-store-id?storeId=sss&txnTypeId=ttt', //'getmaterialsbykid?kid=',
    invMats: 'get-products-by-store-id-orders?storeId=sss&rstoreId=rrr&page=pageNo&size=pageSize&productBadge=null',
    invAvblMats: 'get-available-products-by-stores?storeId=sss&rstoreId=rrr&page=pageNo&size=pageSize',
    transList: 'get-all-stores-txns?page=pageNo&size=pageSize', //'getallstorestxns',
    getBatchbyMatId: 'get-products-by-batch?productId=mmm&storeId=kkk',//getallmaterialsbybatch?kid=kkk&mId=mmm',
    getMatHstry: 'get-product-history?store_id=kkk&product_id=mmm', //'gethistory?kid=1350499&mid=4',
    getMatsMinMax: 'get-products-wise-min-max-cnsmpt?store_id=kkk&product_id=mmm', //'get-material-wise-min-max-cnsmpt?kid=1350499&mid=3',
    abnrmlStkList: 'get-abnormal-stocks?page=pageNo&size=pageSize',
    getAllStrs: 'get-all-stores-by-pranth-id?isSuperAdmin=false&pranthId=', // 'get-all-stores-by-filter?pranthId=ppp', //'get-all-stores?domainId=',
    getAllStrNms: 'get-all-stores-by-pranth-id?storeName=',
    getStrsbyUserId: 'get-stores-by-user?userId=uuu&pranthId=ppp',
    getAllManufac: 'get-all-manufacturers',
    getAllBookings: 'get-bookings-by-filter?page=pageNo&size=pageSize', //getAllOrder: 'get-orders-by-filter?page=pageNo&size=pageSize',
    getBookingsById: 'get-bookings-by-id?booking_id=ooo', //getOrderById: 'get-order-by-oid?oId=ooo',
    getProductsByBookingId: 'get-products-by-booking-id?booking_id=ooo', //getMaterialsByOrderId: 'get-materials-by-order-id?orderid=ooo',
    getOrderHis: 'get/ooo', // getOrderHis: 'get-activity-by-object?objectId=ooo-1', // getBatMatByBookingId: 'get-batch-products-by-booking-id?bookingId=ooo&productId=mmm', //getBatMatByOrdId: 'get-batch-materials-by-order-id?orderid=ooo&materialid=mmm',
    getAllCargos: 'get-all-cargos?page=pageNo&size=pageSize', //getAllShipments: 'get-all-shipments',etAllShipments: 'get-all-shipments',
    getLinkedStores: 'get-mapping-stores?storeId=pranthId&mappingType=ordType&storeBadgeId=', //'get-linked-kiosks?kid=storeId&linktype=ordType', // getLinkedStores: 'get-mapping-stores?storeId=pranthId&mapType=ordType', //'get-linked-kiosks?kid=storeId&linktype=ordType',
    getCargosById: 'get-cargo-details-by-cargo-id?cargoId=sss', //'get-shipment-details-by-shipment-id?shipmentId=sss',
    getProductsByCargoId: 'get-products-by-cargo-id?cargoId=sss', //'get-materials-by-shipment-id?shipmentId=sss',
    getBatMatByCargoId: 'get-batch-details-by-cargo-id?cargoId=sss&productId=mmm', //'get-batch-details-by-shipment-id?shipmentId=sss&materialid=mmm',
    getTags: 'gettags/',
    fulfilBooking: 'fulfil-booking',
    shipmentcreate: 'create',
    getCargobyBookingId: 'get-cargo-by-booking-id?bookingId=ooo', //getShipmentsbyOrderId: 'get-shipments-by-order-id?orderid=ooo',
    getRecmndMats: 'get-recmnd-quty?storeId=sss&productId=mmm', //'get-recmnd-quty?storeId=sss&mid=mmm',
    fulfilOrder: 'fulfill-order',
    getBadges: 'get-by-badge-id?badgeId=4',
    bookingtracking: 'booking-tracking/v1/',
    getbookingstatusbar: 'get-booking-status-by-id?bookingId=ooo',
    getshipmentstatusbar: 'get-cargo-status-by-id?bookingId=ooo',
    getAllocateBats: 'get-products-by-batch?productId=ppp&storeId=sss',
    dispatch: 'dispatch-booking/bbb',
    updateBooking: 'update-booking/bbb',
    updateBookingDetails: 'update-order-details',
    getIntransit: 'get-in-transit-stock?storeId=sss&productId=ppp',
    getAllocated: 'get-allocated-stock?storeId=sss&productId=ppp',
    getTxnBatchbyMatId: 'get-batch-by-txn-id?storeId=kkk&productId=mmm&txnId=ttt',
    getShipmenthistory: 'get-cargo-hist/sss',
    get_all: 'get-all',
    getOne: 'getOne',
    getBatMatByBookingId: 'get-batch-products-by-booking-item-id?bookingItemId=ooo',
    editBooking: 'edit-booking',
    getAsetTyp: 'getbyassettype/id',
    getAsetMdl: 'getbyassetmodel/id',
    getAsetMdlTyp: 'getassetmodelbytype/id',
    getByasetTypId: 'getbyassettype?assettypeid=asetId&isactive=true',
    getByVendorId: 'getbyvendor?vendorid=vendId&isActive=true&assetTypeId=rrrr',
    getStrsByPrnth: 'get-stores-by-pranth?pranthId=pranthid',
    getUsrsByPrnth: 'get-users-by-pranth?pranthId=pranthid',
    getAssetsByserialNo: 'get-assets-by-serial-number?serialNumber=SerNo&typeId=asetTypeId&typeId=&page=pageNo&size=pageSize',
    getByAsetType: 'getbyassettype/asetTypeId',
    getAllocatebyBatch: 'get-allocate-by-batch?IssuingStoreId=sss&productId=ppp',
    getFulfillOrderBats: 'get-fulfilled-order-batches?cargoItemId=ccc',
    getToolTipbyBkngId: 'get-booking-item-tooltip?bookingItemId=bbb',
    getstockdeviantproduct: 'get-stock-deviant-product',
    getAsetDetails: 'getassetdetailsbyid/assetId',
    getbyasetid: 'getbyassetid/assetId',
    getassetdetailsbyfilter: 'getassetdetailsbyfilter?page=pageNo&size=pageSize',
    getAvailTempLog: 'available-temperature-loggers?serialnumber=st&assetPranth=tttttt',
    getMstrStatusbyType: 'getstatusbytype/',
    getComments: 'get-user-comments?objType=type&objId=typeId',
    saveComments: 'save-user-comments',
    creatProduct: 'create-product',
    getPrmsnsbyRole: 'get-role-permissions-by-role/',
    creatStr: 'create-store',
    getStrById: 'get-store-by-id/sss',
    getMatsByFilter: 'get-products-by-filter?pranth=ppp&productName=prdName&BadgeName=bdgeName&page=pageNo&size=pageSize',
    getAllStrByFilter: 'get-all-stores-by-filter?pranthId=ppp&storeId=sss&storeBadgeId=bbb&isMapping=false&mappedStoreId=mpdStrddddd&page=pageNo&size=pageSize&isSuperAdmin=ssss', // 'get-all-stores-by-filter?pranthId=ppp&storeName=sss&tagName=ttt&page=pageNo&size=pageSize',
    getManfacturersById: 'get-producers-by-id?producerId=mmm',
    getStates: 'get-master-locations-by-id?countrtyId=ccc',
    getDistricts: 'get-master-locations-by-id?stateId=sss',
    getBlocks: 'get-master-locations-by-id?districtId=ddd',
    getManufacbyFilter: 'get-producers-by-filter?producerName=ppp&page=pageNo&size=pageSize',
    creatStrRelation: 'create-store-relation',
    getById: 'getbyid/uuu',
    resetPswd: 'reset-password/',
    resndPswd: 'resend-otp/',
    vldtPswd: 'validate-otp/',
    getCnsmptnRprt: 'get-pct-consumption-report',
    getCnsmptnRprtOTs: 'get-pct-consumption-report-by-type',
    expCnsmptnRprt: 'export-consumption-report/rrr/ttt',
    getDscrdsRprt: 'get-pct-discard-report',
    getDscrdsRprtOTs: 'get-pct-discard-report-by-type',
    expDscrdsRprt: 'export-discard-report/rrr/ttt',
    upldFile: 'uploadFile?fileType=Logo&timeOfUpload=dtdt&userId=',
    getCnfgDatabyType: 'get-by-configutation-type/',
    getusersByFilter: 'get-all-users-filter?page=pageNo&size=pageSize&isSuperAdmin=sss',
    getUsersbyRole: 'get-all-users-by-role?roleId=',
    getUsrbyId: 'get-user-by-id?userId=uuu',
    getTransportersByFilter: 'get-carrier-by-filter?page=pageNo&size=pageSize',
    getStkTrndRprt: 'get-pstT-stock-trend-report',
    getStkTrndRprtOTs: 'get-pstT-stock-trend-report-by-type',
    expStkTrndRprt: 'export-stock-trend-report/rrr/ttt',
    getMoreIdntTags: 'get-by-name-and-badge-type?name=&badgetypeid=2',
    finduserbyusername: 'find-user-by-user-name?userName=username',
    getInvcOrdr: 'generate-booking-invoice?booking-id=',
    getInvcShip: 'generate-shipment-invoice?shipping-id=',
    getNtfcnLog: 'get-notification-log?fromDate=fff&toDate=ttt&page=pageNo&size=pageSize',
    getAbnStckRprt: 'get-pstT-abnormal-stock-report',
    getAbnStckRprtOTs: 'get-pstT-abnormal-stock-report-by-type',
    getTransRprt: 'get-all-txn-counts',
    getTransRprtOTs: 'get-all-txns-by-type',
    expTransRprt: 'export-txns-report/ttt',
    expAbnStkRprt: 'export-abnormal-stock-report/rrr/ttt',
    getPrdctsbyExpDt: 'get-stock-view-products-by-expired-date?page=pageNo&size=pageSize',
    getStrsbyPrdct: 'get-stock-view-products-by-filter?page=pageNo&size=pageSize',
    getByModule: 'getallbymodule?moduleId=modtypId&assetEventType=asetType',
    getNtfcnEvntType: 'get-all-by-notification-event-type/tabType',
    chkDupAsstSlNo: 'find-assest-by-serial-number?assestSerialNum=',
    exprtstckrpt: 'export-stock-report', // 'export-stock-report?productId=ppp&pranthId=rrr&page=pageNo&size=pageSize&userId=iii&userName=nnn&email=eml',
    exprtWBstckrpt: 'export-stock-report-with-batch', // 'export-stock-report-with-batch?email=',
    exprtStckrpt1fac: 'export-stock-report-by-store', // 'export-stock-report-by-store?storeId=sss&rstoreId=&pranthId=ppp&userId=uuu&userName=nnn&email=eml',
    exprtStckExprBefr: 'export-expire-before-report?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprtStckvewPrdctByFltr: 'export-store-product-report?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprtStckRpt1WBfac: 'export-stock-report-by-store-with-batch', // 'export-stock-report-by-store-with-batch?storeId=sss&email=',
    exprtstckdvt: 'export-stock-deviant-report?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprttrans: 'export-transaction?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprtTransWB: 'export-transaction-with-batch?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprtIndnts: 'export-bookings?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    exprtCargos: 'export-shipments?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    svRupdate: 'save-or-update-list',
    cnclOrdr: 'cancel-order',
    getRsnsByBdge: 'getreasonbybadge?badgeId=bbb&pranthId=ppp&transactionType=ttt',
    deleteAsset: 'deletebyid/ddd',
    actvtyRprt: 'stores-activity?page=pageNo&size=pageSize',
    actvtyRprtExprt: 'stores-activity-export',
    stksRprts: 'get-stock-report?page=pageNo&size=pageSize',
    stksRprtsExprt: 'export-stock-report?email=',
    rprtsCnsmptn: 'cnsptn-report?page=pageNo&size=pageSize',
    rprtsWastage: 'wastage-report?page=pageNo&size=pageSize',
    rprtsCnsmptnExprt:'cnsptn-report-export',
    rprtsWastageExprt: 'wastage-report-export',
    tempChart: 'temp-graph-details',
    cnfrmOrder: 'confirm-order',
    badgeStore: 'get-gmsd-stores-by-pranth-id?pranthId=rrr&badgeId=sss',
    rlseSave: 'add-release-order',
    ccpStore: 'get-stores',
    cnsmptnStores: 'get-ccp-stores-by-pranth-id',
    trnsfrSave: 'add-transfer-order',
    reopenOrder: 'reopen-order',
    getAsetTypeList: 'get-assets-by-serial-number?page=pageNo&size=pageSize&isSuperAdmin=sss',
    powerAvailableLog: 'power-availible-details',
    delRelationAsset: 'deletebyassetid/rrrrr',
    getBtchWiseDtls: 'get-batch-wise-details?batchNo=bbb&producerId=ppp&productId=mmm',
    bulkUpload: 'assetbulksave',
    invntryBlkSave: 'bulksave',
    usrsBlkSave: 'userbulksave',
    exprtAstLst: 'export-asset-report',
    tempGrphExprt: 'temp-graph-details-export',
    usrActivityReport: 'get-user-activity-report?activityTimePeriod=ttt',
    usrActivityReportExprt: 'get-user-activity-report-export?activityTimePeriod=ttt&email=eee&userId=iii&userName=nnn',
    usrLst: 'get-users-by-pranth-id',
    usrExprtsData: 'getuserfiles?&page=ttt&size=sss',
    txnCnutData: 'txn-count-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    stkTrndData: 'stock-trend-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    cStkData: 'closing-stock-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    stkTrndDataExpt: 'export-stock-trends-report?type=ddd&labelType=ttt',
    cStkDataExpt: 'closing-stock-report-export?type=ddd&labelType=ttt',
    updateAstStatus: 'updatestatus',
    dscrdStckRprt: 'discard-stock-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    tempAstRelatn: 'find-asset-relation',
    abnrmlStck: 'abnormal-stock-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    dscrpncsLst: 'get-discrepancy-bookings-by-filter?page=pageNo&size=pageSize',
    IndentDscrpncsExpt: 'export-discrepancy-bookings?email=eml',
    dscrpncsReport: 'booking-dicrpncy-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    dbINVIndctr: 'get-stock-count-indicators',
    dbINVMap: 'get-stock-count-details',
    dbACTIndctr: 'dashboard-activity-indicators?mapType=',
    dbTempMap: 'get-temperature-map-details?isUnknown=nnn&isLow=lll&isHigh=hhh&isNormal=rrr',
    dbTempIndctr: 'get-asset-temperature-indicators?isUnknown=nnn&isLow=lll&isHigh=hhh&isNormal=rrr',
    dbACTMap: 'dashboard-stores-activity?mapType=mmm&activityType=',
    dbActExprt: 'dashboard-activity-export?mapType=mmm&activityType=',
    dbInvExprt: 'dashboard-stock-export',
    respnseTime: 'booking-response-time-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    delMat: 'deletebyid/rrrrr',
    editMat: 'get-product-by-id?productId=iiiii',
    crtPrduct: 'create-product/iiiii',
    expProducts: 'export-products?pranth=ppp&productName=prdName&BadgeName=bdgeName',
    expUsers: 'export-users?isSuperAdmin=sss',
    sendEmail: 'send-email',
    stpFcGetPrfle: 'get-store-profile?storeId=sss',
    stpFcGetAlprdctsBystrId: 'get-all-products-by-store-id?badges=sss&storeId=ttt',
    delByMatId: 'deletebyid?id=mmm&storeId=null',
    astCapacityRpt: 'asset-capacity-report?type=ddd&labelType=ttt',
    astStatusRpt: 'asset-status-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    astRspnsTmRepar: 'asset-repair-time-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    astUpTmRpt: 'asset-uptime-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    astTmpExcuson: 'temperature-excursion-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    astPwrAvlblty: 'power-availability-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize',
    stkAvbltyRpt: 'stock-availability-report?type=ddd&labelType=ttt&page=pageNo&size=pageSize', // stkAvbltyRpt: 'stock-availability-report',
    getHrchryDtls: 'getHierarchyByPranth?id=',
    updateAllCnfgPrnths: 'update-configuration-to-all-child-pranths',
    updateCnfgPrnths: 'update-configuration-to-child',
    exprtStckvewPrdctByFltrWB: 'export-store-product-report-with-batch?pranthId=rrr&userId=iii&userName=nnn&email=eml',
    dbIndnts: 'get-indent-details',
    actvtyDbOvw: 'get-activity-details',
    actulDataOfTrns: 'get-activiy-datatransaction-view?page=pageNo&size=pageSize',
    bulkSveMtrl: 'materialbulksave',
    bulkSveFaclty: 'setupfacilitybulksave',
    actulDataOfTrnsExpt: 'export-actualdatatrans?file=',
    dbStckMangmentView: 'dashboard-stockmanagement-view?page=pageNo&size=pageSize',
    dbStckMangmentExpt: 'dashboard-stockmanagement-overview-export',
    getByStrId: 'get-by-id/',
    mappedPranthsGetAll: 'getallmappedpranths?page=pageNo&size=pageSize&name=nnn',
    allPrnths : 'getallpranths?name=nnn&page=pageNo&size=pageSize',
    fcltyStkMangment: 'get-stock-managment-by-store-id?storeId=sss&productId=rrr&page=pageNo&size=pageSize&productBadge=null',
    dbTmpExprt: 'temperature-export?isUnknown=nnn&isLow=lll&isHigh=hhh&isNormal=rrr',
    dbCCEDonut: 'get-cce-indicators',
    dbCCEMap: 'get-cce-details-byfilter',
    dbCCEExprt: 'cce-status-export?',
    getActTabDtls: 'get-activity-details-by-selection',
    dbIndbtExprt:'get-indent-details-export',
    dbActvtyExprt:'export-dashboard-activity-overview',
    exprtFaclty:'export-stores-by-filter?storeId=sss&storeBadgeId=bbb&Email=eee&userName=nnn&isSuperAdmin=ssss',
    dltOrder:'cancel-booking-item?userId=iii&pranthId=ppp', 
    getPrnthDesc: 'getpermissionbypranthid/slctdDmnID',
    updatePrmsn: 'updatepermission',
    loadChldDmns: 'getdefaultcodes',
    getdomainactivitydetails: 'get-domain-activity-details?pranthId=ppp&type=ttt&onClick=ccc&page=pageNo&size=pageSize',
    getdomainactivitydtls: 'get-domain-activity-details?type=ttt&onClick=ccc&page=pageNo&size=pageSize',
    exprtDmntActvty:'export-domainActivity?pranthId=ppp&type=ttt&onClick=ccc&email=eee',
    getPopOverDataDmnActvty:'get-domain-activity-details-storecount?pranthId=ppp&type=ttt',
    getTtlCntOfTrnsctn:'get-all-stores-txns-count',
    getTtlCntOfStckDvnt:'get-stock-deviant-total-count',
    getStkrprtTtlCnt:'get-stock-report-total-count',
    ordrDscrpnsExprt:'booking-dicrpncy-report-export?type=ddd&labelType=ttt',
    abnrmlStkExprt:'export-abnormal-stock-report?type=ddd&labelType=ttt&email=eee',
    dscrdStkRprtExprt:'discard-stock-export?type=ddd&labelType=ttt&email=eee',
    respnseTimeExprt: 'export-booking-response-time-report?type=ddd&labelType=ttt',
    txnCountExpt:'export-txn-count-report?type=ddd&labelType=ttt',
    cceStatusExpt:'export-asset-status-report?type=ddd&labelType=ttt',
    respnseTmRepairExpt:'export-asset-repair-time-report?type=ddd&labelType=ttt',
    stkavilbltyExpt:'export-stock-availability-report?type=ddd&labelType=ttt',
    astPwrAvbltyExprt:'export-power-availability-report?type=ddd&labelType=ttt',
    upTimeExprt:'export-asset-uptime-report?type=ddd&labelType=ttt',
    astTmpExcusonExprt:'export-temperature-excursion-report?type=ddd&labelType=ttt',
    updatePrnth: 'updatepranth',
    getUnmapedPrnths: 'fetchunmappedpranth?parentPranthId=',
    deLnkPrnth: 'de-linkpranths?mappedPranthId=',
    saveUsrPrnth: 'saveuserpranth?', 
    getUsrPrnth: 'getuserpranth?userId=',
    dltUsrPrnth: 'deletebyuserid?userId=ttt&pranthId=kkk',
    getChldPrnth: 'fetchchildpranth?parentPranthId=',
    getUnMappedStrs: 'get-all-stores-by-product-id?&productId=rrr&page=pageNo&size=pageSize&storeName=sss',
    saveStrsForMtrl: 'saveorupdate',
    saveMtrlsForStrs: 'add',
    dbOvrviewMtrl: 'get-stock-by-materials',
    dbSmMtrlByName: 'get-material-barchart-by-pranth-id',
    getPrductsBybadgId:'get-products-by-store-product-badge?storeId=sss&badgeId=0',
    getAllPushCnfgTypes: 'getall-push-configuration',
    updtCnfgChldDmnsNew: 'update-configuration-to-all-child-pranths-new',
    chkDupFacNme: 'find-store-by-store-name?storeName=',
    pullAllCnfg: 'get-by-configutation-type-new',
    getUsrByPrnth: 'get-user-by-pranth?pranthId=',
    getUsrTgsByPrth: 'get-user-tags-by-pranth?pranthId=',
    getTxnExprt: 'getuserfilesmonth?page=ppp&size=sss',
    pshMtrl : 'push-product?pranthId=',
    getUsrByPrnthRole: 'getuserbypranthrole?roleId=',
    fndById:'findById/',
    trnspterById: 'findByid/',
    trnspterExpt: 'export-Carriers?email=eee',
    cratPrductprducr: 'create-product-producer',
    exptAllPrnths: 'exportAllpranths',
    getAllPrdts: 'get-all-products?badgeIds=bbb&producerId=iii&flag=aaa&page=pageNo&size=pageSize',
    dltMnfrId:'deletebyid/id=iii&producerId=ppp',
    exprtManftr:'export-producers?email=eee',
    prducrById:'get-producers-by-id?producerId=iii',
    mveStre: 'move-store',
    ftchChldPrnth: 'fetchconsolidatepranths',
    getLatLngSVM: 'get-stock-view-map-view',
    pwrAvlbltyDtlsExpt: 'power-available-details-export',
    chckMac:'checkmac',
    getUsersByStoreId:'get-users-store-id?storeId=iii',
    getVndrByName:'get-asset-vendors-by-filter?vendorName=nnn&page=pageNo&size=pageSize',
    getMdlLst:'get-asset-model-by-filter?modelName=nnn&page=pageNo&size=pageSize',
    exprtVndrs: 'export-vendors?Email=eee&vendorName=vvv',
    exprtMdl: 'export-models?Email=eee&modelName=mmm',
    exprtAsst: 'export-assets?Email=eee&manufacturerId=mmm&assetModelId=aaa&assetTypes=ttt', 
    getPrchseIndnts: 'get-purchases-by-filter?page=pageNo&size=pageSize',
    getAllProducers: 'getallproducers',
    getIndntbyID: 'get-purchase-booking-by-id?purchase_id=prcId',
    getCnsgnmntStr: 'get-all-stores-by-pranth-id?isconsignee=true',
    getCnsgnPrchsById: 'get-consignes-purchase-id?purchase_id=nnn&storeId=sss',
    cnsinWiseCnsumton: 'consigne-wise-consumption?page=pageNo&size=pageSize',
    getCnsgnQntityLst: 'get-consigne-bookings-by-id?consigneBookingId=', 
    delQntityInd: 'delete?indentId=', 
    prchsPrgrs: 'get-consignee-booking-id?consigneBookingId=',
    prchsOrdDtls: 'get-details-by-consigne-booking-id?consigneBookingId=',
    prchsAlctdBtchsLst: 'get-batches-consigne-booking-id?consigneBookingId=',
    prchsAllctBking: 'allocate-booking', 
    prchsRdyfrDsptch: 'ready-for-disptach-booking',
    astCpctyByStr: 'asset-capacity-by-store?page=pageNo&size=pageSize',
    asstsErrLst: 'geterrorcodes?displaySource=kkk',
    saveCnsgnSply: 'saveconsigneesupply',
    getCnsgnSplyLst: 'get-supply-list-by-consignee-id?producerConsigneeId=',
    getIndntDtlsSpl: 'get-details-by-supply-id?consigneSupplyId=',
    getFirmWareReport : 'export-assets-firmware?Email=nnn&assetTypes=1',
    cnsgnRcmndQntity: 'consigne-wise-rcmdqty',
    getPrdcrTxn: 'get-producer-txn-by-filter',
    exprtPrdcrTxn: 'export-producer-txn?email=eml',
    cnsineWseExpt : 'consigne-wise-export',
    fulFillUpld: 'upload-var?consigneBookingId=iii&fileTypeId=fff',
    relsFulFillUpld: 'upload-var?cargoItemId=iii&fileTypeId=fff',
    getVndrAstTyp: 'get-vendor-by-assettypeid?assetTypeId=aaa',
    getDtlsByCnsneId: "fetch-details-by-consigne-booking-id?consigneBookingId=ccc",
    cnfmSplyCyle: "confirm-supply-cycle?producerBookingId=ccc&storeId=sss",
    getPrdtsByMnf: "get-products-by-producer-id?producerId=ppp",
    exptAstCpcty: 'export-assetCapacity-report?email=eee',
    dtlsByVargoId: 'fetch-details-by-cargo-item-id?cargoItemId=ccc',
    strByLocns:"get-store-by-hierarchy"
  },
  upldFileTmplts: {
    asset: '/onehealth-files/downloadFile?fileName=Assets_Bulk_Upload_Template.csv&fileType=AssetBulkUpload',
    user: '/onehealth-files/downloadFile?fileName=Users_Bulk_Upload_Template.csv&fileType=UserBulkUpload',
    material: '/onehealth-files/downloadFile?fileName=Materials_Bulk_Upload_Template.csv&fileType=MaterialBulkUpload',
    facility: '/onehealth-files/downloadFile?fileName=Facility_Bulk_Upload_Template.csv&fileType=FacilityBulkUpload',
    inventory: '/onehealth-files/downloadFile?fileName=Inventory_Bulk_Upload_Template.csv&fileType=StockBulkUpdate',
  },
  clrsMinMax: {
    stkOuts: 'rgb(228 73 73)', // #e44949 // 'rgb(245, 169, 169)', // '#f5a9a9', // '#7e3b81', // #faf3d1
    lessMin: 'rgb(234 208 62)', // #ead03e // 'rgb(249, 232, 137)', // '#f9e889', // '#8f7f2f', // #d9edf7
    moreMax: 'rgb(194 228 243)', // #c2e4f3 // 'rgb(55, 144, 55)', // '#379037', // '#3c722c', // #f2dede
    normal: 'rgb(55 209 137)', // #37d189
  },
  clrCds: {
    green: ["#d7f6e7", "#afedd0", "#87e3b8", "#5fdaa1", "#37d189"],
    red: ["#fddada", "#fcb5b5", "#fa9090", "#f96b6b", "#f74646"],
    orange: ["#fdeadb", "#fac9a6", "#f8af79", "#f5944d", "#f37920"],
    skyBlue: ["#e3f6fa", "#c8ecf5", "#ade3ef", "#91d9ea", "#75d0e5"],
    yellow: ["#f8f9d0", "#f7fcb3", "#f4fa8c", "#f0f966", "#ecf740"],
    navyBlue: ["#d5d5e6", "#acacda", "#8282b3", "#59599a", "#2f2f81"],
    grey: ['#dcdcdc', '#d3d3d3', '#c0c0c0', '#a9a9a9', '#808080'],
    nullClr: ['#ffffff']
  },
  cnfgTypes: {
    general: '1',
    inventory: '3',
    event: '13',
    orders: '4',
    notification: '6',
    badge: '7',
    dashboard: '11',
    ntfcnBltnBrd: '18',
  },
  eventSummaryTypes: {
    indent:'34',
    stock:'35',
    assests:'36',
    activity:'37',
  },
  tagTypes: {
    product: 1, // mats: 1,
    facility: 2,  // locs: 0,
    user: 3,  // user: 4,
    order: 4, // ordr: 3,
    manufacturer: 5, // test: 5,
  },
  patterns: {
    numerics: '/^-?(0|[1-9]\d*)?$/',
    numericsby5: '^[1-9]*[0]*((0)|(5))$', // '/^\d*\d?((5)|(0))\??$/', // '/^\d*\d?((5)|(0))\.?((0)|(00))?$/', -- commented will check decimal values
    numericsby10: '^[1-9]*[0]*0$', // '/^\d*\d?((10)|(0))\.?((0)|(00))?$/', // '/^-?(0|[1-9]*0$)?$/',
    pswd1L1N: '(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{6,}',
    alphaNum: '(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9\d$@].{6,}', // /((^[0-9]+[a-z]+[A-Z]+)|(^[a-z]+[A-Z]+[0-9]+))+$/i, // '/[^a-zA-Z0-9]/g', // '/[a-zA-Z0-9]/',
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    email2: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
    mble: '^[6-9]{1}[0-9]{9,12}$'
  },
  fsnChrtProps: {
    width: '100%',
    height: '500',
    format: 'json',
  },
  firebase: {
    apiKey: "AIzaSyBFZqE2eX7jEuoi9jJUj27JAFxsg-OGAog",
    authDomain: "evin-demo.firebaseapp.com",
    projectId: "evin-demo",
    storageBucket: "evin-demo.appspot.com",
    messagingSenderId: "606706226303",
    appId: "1:606706226303:web:f7eb6d643061a17ca51b4a",
  },
  cnfgTrnsRsnType: {
    issue: 'Issue',
    stk_in: 'Receipt',
    stk_edit: 'Transaction Stock Count',
    transfer: 'Transaction Transfer',
    discard: 'Transaction Discard',
    new_indent: 'New Booking',
    update_indent: 'Update Booking',
    fulfill_indent: 'Fulfill Booking',
    cancel_indent: 'Cancel Order',
  },
  cnfgTrnsMatStsType: {
    issue: 'Issue',
    stk_in: 'Receipt',
    stk_edit: 'Stock Count',
    transfer: 'Transfer',
    discard: 'Discard',
    issueTSM: 'Issue-temperature sensitive',
    stk_inTSM: 'Receipt-temperature sensitive',
    stk_editTSM: 'Stock Count-temperature sensitive',
    transferTSM: 'Transfer-temperature sensitive',
    discardTSM: 'Discard-temperature sensitive',
  },
  dayMonth: {
    Day: '<b>Day -</b> Past 30 days from the selected date',
    Month: '<b>Month -</b> Past 6 Months from the selected Month',
  },
  commonPranthId: '1343774',
};