import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
  // encapsulation
})
export class PrivacyPolicyComponent implements OnInit {
  environmentUrl=location.href.includes('localhost')?'https://avinonline.in':location.origin.toLowerCase();
  // title=location.origin.includes('qa')?'SHWP Training ':'SHWP';
  title='AVIN';
 
  
    constructor() { }
  
    ngOnInit(): void {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
  });
  
    }
  
  }
