import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { TransactionsListComponent } from 'src/app/Components/evin/Inventory/transactions/transactions-list/transactions-list.component';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

@Component({
  selector: 'evin-create-transactions',
  templateUrl: './create-transactions.component.html',
  styleUrls: ['./create-transactions.component.css'],
  providers: [TransactionsListComponent]
})

export class CreateTransactionsComponent extends BaseComponent implements OnInit {

  lstMats: any[] = []; lstAllMats: any[] = []; lstSrchStrs: any[] = []; str = ''; lstFacs: any[] = []; lstLPrnts: any[] = []; isSbmtd = false;
  lstAllProducers: any[] = []; noRcrdsTxt = environment.TblNoRcrds; tdDate: Date = new Date(); toExpDate: Date = new Date(); slctdStrId: any;
  lstChsnMats: any[] = []; producer_id: any; transForm: FormGroup = this.fb.group({
    transType: ['', [Validators.required]], matCatgFilt: [''],
    transactualdate: ['', [Validators.required]], linked_store_id: [''], srchMtrl: [''], products: this.fb.array([]), facility: ['',
      Validators.required], quantity: ['']
  }); lstMCF: any[] = []; storeID = ''; lstLinkedStrs: any[] = []; lstTxnTyps: any[] = []; rStore: any;
  lstRsnDscrd: any[] = []; lstRsnTSC: any[] = []; lstRsnVVM: any[] = []; actTxnData: any; actTxnDiscard = 'None'; actTxnIssue = 'None';
  actTxnReceipt = 'None'; actTxnStock_count = 'None'; actTxnTransfer = 'None'; isDateExpired: boolean = false; maxAvail: any; maxBAvail: any;
  actTxnFlag = false; rqdFlag = false; lstRsnsRcpt: any[] = []; lstRsnsIssue: any[] = []; isRsnMndtry = false; rsnDefault = ''; paramsTab = '';
  stIssue: any[] = []; stReceipt: any[] = []; stStkCnt: any[] = []; stDscrd: any[] = []; stTrnsfr: any[] = []; isStsMndtry = false;
  isLclDev = environment.isLclDev; appBdgs: any[] = []; lstAllRsns: any[] = []; lstFacs1: any[] = []; isResetClk = false; isSUFclty = false;
  cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData')); isReqLL = '0'; lgnUsrDtls: boolean = false; cnfgVal: any = null;
  hdnTT = { isHdnINU: false, isHdnSI: false, isHdnSE: false, isHdnTrs: false, isHdnDsc: false };
  // Added BY Ghouse 15-03-2023 for reason_name
  selectedTypeReason:string=''
  constructor(private fb: FormBuilder,
    public dtPipe: DatePipe,
    public route: Router,
    public glblSvc: GlobalService,
    private ngbMdl: NgbModal,
    public cmpntTL: TransactionsListComponent, private rt: ActivatedRoute) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { s.forEach(e => { Object.assign(e, { store_loc: `${e.storeName}...${e.location}` }); }), this.lstFacs = s; }),
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMCF = s.filter(f => f.badgeTypeId == environment.tagTypes.product); }),
      this.glblSvc.sbjctMstrData.subscribe(s => {
        this.lstTxnTyps = s.txnTypeLst, this.lstAllRsns = s.rsnsLst,
        this.lstRsnDscrd = s.rsnsLst?.filter((f: any) => f.reasonType === environment.cnfgTrnsRsnType.discard && f.badgeId == null),
        this.lstRsnTSC = s.rsnsLst?.filter((f: any) => f.reasonType === environment.cnfgTrnsRsnType.stk_edit && f.badgeId == null),
        this.lstRsnVVM = s.rsnsLst?.filter((f: any) => f.reasonType === environment.cnfgTrnsRsnType.transfer && f.badgeId == null),
        this.lstRsnsRcpt = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.stk_in && f.badgeId == null),
        this.lstRsnsIssue = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.issue && f.badgeId == null);
      }), this.glblSvc.sbjctBdgsList.subscribe(a => this.appBdgs = a), this.glblSvc.sbjctManfsLst.subscribe(s => this.lstAllProducers = s),
      glblSvc.sbjctMatStsLst.subscribe(s => {
        this.stIssue = s.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.issue),
        this.stReceipt = s.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_in),
        this.stStkCnt = s.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_edit),
        this.stDscrd = s.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.discard),
        this.stTrnsfr = s.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.transfer);
      }), this.isSUFclty = false;
    if (this.lstFacs.length == 1)
      this.transForm.get('facility')?.setValue(this.lstFacs[0].storeName), this.slctdStrId = this.lstFacs[0].storeId,
        this.str = this.lstFacs[0].storeName;
    this.glblSvc.sbjctTabsPage.subscribe(s => {
      if (s == 'trans-new') {
        this.isResetClk = false, this.onReset(), this.glblSvc.sbjctCnfgData.subscribe(a => this.actTxnData = a.cnfgInvData?.date_of_actual_transaction),
          this.actTxnDiscard = this.actTxnData?.discards?.enable_capturing_date_of_actual_transaction?.date_of_actual_transaction,
          this.actTxnIssue = this.actTxnData?.issue?.enable_capturing_date_of_actual_transaction?.date_of_actual_transaction,
          this.actTxnReceipt = this.actTxnData?.receipt?.enable_capturing_date_of_actual_transaction?.date_of_actual_transaction,
          this.actTxnStock_count = this.actTxnData?.stock_count?.enable_capturing_date_of_actual_transaction?.date_of_actual_transaction,
          this.actTxnTransfer = this.actTxnData?.transfer?.enable_capturing_date_of_actual_transaction?.date_of_actual_transaction;
      }
      if (localStorage.getItem('suFcltyId') != null && location.href.includes('setup-fclty-smry')) {
        if (this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId')).length > 0)
          this.transForm.get('facility')?.setValue(this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName),
            this.str = this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, this.isSUFclty = true
        this.slctdStrId = localStorage.getItem('suFcltyId');
      }
    });
  }

  ngOnInit(): void {
    this.rt.queryParams.subscribe(params => { this.paramsTab = params.tab; });
    this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null; this.dropdnsHdn();
  }

  dropdnsHdn() {
    this.hdnTT = { isHdnTrs: (this.cnfgVal?.permissionRoles[3]?.configuredValue?.value) ?? false,
      isHdnDsc: this.cnfgVal?.permissionRoles[7]?.configuredValue?.value ?? false,
      isHdnINU: this.cnfgVal?.permissionRoles[6]?.configuredValue?.value ?? false,
      isHdnSE: this.cnfgVal?.permissionRoles[5]?.configuredValue?.value ?? false,
      isHdnSI: this.cnfgVal?.permissionRoles[1]?.configuredValue?.value ?? false };
    if (this.hdnTT.isHdnTrs)
      this.lstTxnTyps = this.lstTxnTyps.filter(f => f.name != 'Transfers');
    if (this.hdnTT.isHdnINU)
      this.lstTxnTyps = this.lstTxnTyps.filter(f => f.name != 'Issues/Net Utilization');
    if (this.hdnTT.isHdnSE)
      this.lstTxnTyps = this.lstTxnTyps.filter(f => f.name != 'Stock-Edit');
    if (this.hdnTT.isHdnSI)
      this.lstTxnTyps = this.lstTxnTyps.filter(f => f.name != 'Stock-In');
    if (this.hdnTT.isHdnDsc)
      this.lstTxnTyps = this.lstTxnTyps.filter(f => f.name != 'Stock-Discards');
  }

  onChngDT(dtVal: any) {
    if (dtVal != null && dtVal != '')
      this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngManDT(dtVal2: any) {
    if (dtVal2 != null && dtVal2 != '')
      this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  onChngDe(dtVal3: any) {
    if (dtVal3 != null && dtVal3 != '')
      this.cstmDt3 = dtVal3 ? [] : this.cstmDtDef3;
  }

  get fc() { return this.transForm.controls; }

  clearBSDate(ctrl: string, indx: number, type: string = 'l') {
    if (type == 'l')
      this.GetListMB(indx)?.controls[0]?.get(ctrl)?.setValue('');
    else
      this.transForm.get(ctrl)?.setValue('');
  }

  onChngFclty(evnt: any, evntType: string, type = 's1') {
    let txnType = this.transForm.get('transType')?.value, isStkIn = txnType != '' && txnType === '2',
      event = (evntType == 'chng' ? evnt.target.value : evnt.value);
    this.isSbmtd = false, this.str = '';
    if (event == "" || event == undefined) {
      // this.setElmntFocus('trnStr');
      if (type == 's1') {
        [this.lstSrchStrs, this.lstMats, this.str] = [[], [], ''], this.slctdStrId = null,
          this.transForm.get('facility')?.setValue('');
        if (txnType == '5')
          this.lstFacs1 = [], this.storeID = '', this.transForm.get('linked_store_id')?.setValue('');
      }
      else
        this.storeID = '', this.transForm.get('linked_store_id')?.setValue('');
    }
    else {
      // if (event == '')
      //    //, this.transForm.get('facility')?.setValue('');
      // else {
      if (type == 's1') {
        this.GetMatrls().controls.length = 0;
        if (this.lstFacs.filter(a => a.storeName == event).length != 0) {
          for (let s = 0; s < this.lstFacs.length; s++) {
            if (this.lstFacs[s].storeName == event)
              this.slctdStrId = this.lstFacs[s].storeId; //, this.transForm.get('facility')?.setValue(this.slctdStrId);
          }
        }
        if (txnType == '5')
          this.storeID = '', this.transForm.get('linked_store_id')?.setValue(''), this.lstFacs1 = this.lstFacs.filter(a =>
            a.storeName == event).length != 0 ? this.lstFacs.filter(a => a.storeName != event) : this.lstFacs;
      }
      else if (type == 's2') {
        if (this.lstLinkedStrs.filter(f => f.mapped_store_name == event).length != 0)
          this.storeID = this.lstLinkedStrs.filter(f => f.mapped_store_name == event)[0].mapped_store_id; // this.lstFacs.filter(f => f.storeName == evnt?.value)[0].storeId;
        else
          this.storeID = "", this.transForm.get('linked_store_id')?.setValue('');
      }
      else {
        if (this.lstFacs1?.filter(f => f.storeName == event).length != 0) {
          this.storeID = '', this.transForm.get('linked_store_id')?.setValue(''), this.rStore = '', this.GetMatrls().controls.length = 0;
          let slctdFac = this.lstFacs1?.filter(f => f.storeName == event)[0];
          this.storeID = slctdFac.storeId, this.transForm.get('linked_store_id')?.setValue(slctdFac.storeName),
            this.rStore = slctdFac.storeId, this.GetMatrls().controls.length = 0, this.LoadMatsbyRecvingStoreId();
        }
        else
          this.storeID = "", this.transForm.get('linked_store_id')?.setValue('');
      }
      if (type == 's1' && this.slctdStrId != null) {
        this.str = event, this.LoadMatsbyStoreId(this.slctdStrId);
        if (txnType != '5')
          this.GetLnkdStores(this.slctdStrId, isStkIn ? 'v' : 'c')
      }
      // }
    }
  }

  GetLnkdStores(kioskId: any, ordrType: any) {
    if (kioskId) {
      this.lstLinkedStrs = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
        environment.apiMthds.getLinkedStores.replace('pranthId', kioskId).replace('ordType', ordrType), null).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              this.lstLinkedStrs = this.result.data != null ? this.result.data : [];
              if (this.lstLinkedStrs.length == 1)
                this.transForm.get('linked_store_id')?.setValue(''), // this.lstLinkedStrs[0]?.mapped_store_name
                  this.storeID = ''; // this.lstLinkedStrs[0]?.mapped_store_id;
            }
          }
        });
    }
  }

  /* getProducers() {
    if (localStorage.getItem('isMnfrAPICald') == null) {
      localStorage.setItem('isMnfrAPICald', '1'), this.FetchData(environment.apiCalls.get, environment.apiPaths.producer,
          environment.apiMthds.getAll, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.lstAllProducers = this.result.data;
        }
        setTimeout(() => { localStorage.removeItem('isMnfrAPICald'); }, 500);
      });
    }
  }

  getStatus() {
    if (localStorage.getItem('isStsAPICald') == null) {
      localStorage.setItem('isStsAPICald', '1'), this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrSts, environment.apiMthds.
          getAll, null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.stIssue = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.issue),
                this.stReceipt = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_in),
                this.stStkCnt = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.stk_edit),
                this.stDscrd = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.discard),
                this.stTrnsfr = this.result.data.filter((f: any) => f.statusType == environment.cnfgTrnsMatStsType.transfer);
            }
          }
        }
        setTimeout(() => { localStorage.removeItem('isStsAPICald'); }, 500);
      });
    }
  } */

  onChngTT() {
    let val = this.transForm.get('transType')?.value, txnName = val == '' ? '' : this.lstTxnTyps.filter(a => a.id == val)[0].name;
    this.actTxnFlag = false, this.rqdFlag = false; this.isRsnMndtry = false, this.rsnDefault = '', this.isStsMndtry = false,
      this.transForm.get('transactualdate')?.setValue(''), this.isSUFclty = false; //, this.transForm.get('transactualdate')?.setValidators(null)
    if (this.lstFacs.length == 1)
      this.str = this.lstFacs[0].storeName;
    if (txnName != '') {
      if (txnName == 'Issues/Net Utilization') {
        if (this.actTxnIssue != 'None') {
          this.actTxnFlag = true;
          /* if (this.actTxnIssue == 'Mandatory')
            this.transForm.get('transactualdate')?.setValidators([Validators.required]), this.rqdFlag = true;
          else 
            this.transForm.get('transactualdate')?.setValidators(null); */
        }
        this.isRsnMndtry = this.cnfgData?.cnfgInvData?.transaction_reasons?.issue?.make_this_field_mandatory ?? false,
          this.isStsMndtry = this.cnfgData?.cnfgInvData?.material_status?.issue?.status?.make_this_field_mandatory ?? false;
        /* if (this.lstRsnsIssue[0]?.isReasonMandatory)
          this.isRsnMndtry = true;
        else
          this.isRsnMndtry = false;
        if (this.stIssue[0]?.isStatusMandatory)
          this.isStsMndtry = true;
        else
          this.isStsMndtry = false; */
        if (this.lstRsnsIssue.filter(a => a.isDefault == true).length > 0)
          this.rsnDefault = this.lstRsnsIssue.filter(a => a.isDefault == true)[0].reasonId;
      }
      if (txnName == 'Transfers') {
        if (this.actTxnTransfer != 'None') {
          this.actTxnFlag = true;
          /* if (this.actTxnTransfer == 'Mandatory')
            this.transForm.get('transactualdate')?.setValidators([Validators.required]), this.rqdFlag = true;
          else 
            this.transForm.get('transactualdate')?.setValidators(null); */
        }
        this.isRsnMndtry = this.cnfgData?.cnfgInvData?.transaction_reasons?.transfer?.make_this_field_mandatory ?? false,
          this.isStsMndtry = this.cnfgData?.cnfgInvData?.material_status?.transfer?.status?.make_this_field_mandatory ?? false;
        /* if (this.lstRsnVVM[0]?.isReasonMandatory)
          this.isRsnMndtry = true;
        else
          this.isRsnMndtry = false;
        if (this.stTrnsfr[0]?.isStatusMandatory)
          this.isStsMndtry = true;
        else
          this.isStsMndtry = false; */
        if (this.lstRsnVVM.filter(a => a.isDefault == true).length > 0)
          this.rsnDefault = this.lstRsnVVM.filter(a => a.isDefault == true)[0].reasonId;
      }
      if (txnName == 'Stock-In') {
        if (this.actTxnReceipt != 'None') {
          this.actTxnFlag = true;
          /* if (this.actTxnReceipt == 'Mandatory')
            this.transForm.get('transactualdate')?.setValidators([Validators.required]), this.rqdFlag = true;
          else 
            this.transForm.get('transactualdate')?.setValidators(null); */
        }
        this.isRsnMndtry = this.cnfgData?.cnfgInvData?.transaction_reasons?.receipt?.make_this_field_mandatory ?? false,
          this.isStsMndtry = this.cnfgData?.cnfgInvData?.material_status?.receipt?.status?.make_this_field_mandatory ?? false;
        /* if (this.lstRsnsRcpt[0]?.isReasonMandatory)
          this.isRsnMndtry = true;
        else
          this.isRsnMndtry = false;
        if (this.stReceipt[0]?.isStatusMandatory)
          this.isStsMndtry = true;
        else
          this.isStsMndtry = false; */
        if (this.lstRsnsRcpt.filter(a => a.isDefault == true).length > 0)
          this.rsnDefault = this.lstRsnsRcpt.filter(a => a.isDefault == true)[0].reasonId;
      }
      if (txnName == 'Stock-Edit') {
        if (this.actTxnStock_count != 'None') {
          this.actTxnFlag = true;
          /* if (this.actTxnStock_count == 'Mandatory')
            this.transForm.get('transactualdate')?.setValidators([Validators.required]), this.rqdFlag = true;
          else 
            this.transForm.get('transactualdate')?.setValidators(null); */
        }
        this.isRsnMndtry = this.cnfgData?.cnfgInvData?.transaction_reasons?.stock_count?.make_this_field_mandatory ?? false,
          this.isStsMndtry = this.cnfgData?.cnfgInvData?.material_status?.stock_count?.status?.make_this_field_mandatory ?? false;
        /* if (this.lstRsnTSC[0]?.isReasonMandatory)
          this.isRsnMndtry = true;
        else
          this.isRsnMndtry = false;
        if (this.stStkCnt[0]?.isStatusMandatory)
          this.isStsMndtry = true;
        else
          this.isStsMndtry = false; */
        if (this.lstRsnTSC.filter(a => a.isDefault == true).length > 0)
          this.rsnDefault = this.lstRsnTSC.filter(a => a.isDefault == true)[0].reasonId;
      }
      if (txnName == 'Stock-Discards') {
        if (this.actTxnDiscard != 'None') {
          this.actTxnFlag = true;
          /* if (this.actTxnDiscard == 'Mandatory')
            this.transForm.get('transactualdate')?.setValidators([Validators.required]), this.rqdFlag = true;
          else 
            this.transForm.get('transactualdate')?.setValidators(null); */
        }
        this.isRsnMndtry = this.cnfgData?.cnfgInvData?.transaction_reasons?.discards?.make_this_field_mandatory ?? false,
          this.isStsMndtry = this.cnfgData?.cnfgInvData?.material_status?.discard?.status?.make_this_field_mandatory ?? false;
        /* if (this.lstRsnDscrd[0]?.isReasonMandatory)
          this.isRsnMndtry = true;
        else
          this.isRsnMndtry = false;
        if (this.stDscrd[0]?.isStatusMandatory)
          this.isStsMndtry = true;
        else
          this.isStsMndtry = false; */
        if (this.lstRsnDscrd.filter(a => a.isDefault == true).length > 0)
          this.rsnDefault = this.lstRsnDscrd.filter(a => a.isDefault == true)[0].reasonId;
      }
      if (this.lstFacs.length == 1) {
        this.LoadMatsbyStoreId(this.slctdStrId);
        if (txnName !== 'Transfers')
          this.GetLnkdStores(this.slctdStrId, val != '' && val === '2' ? 'v' : 'c');
      }
      else
        this.slctdStrId = null, this.storeID = '', this.transForm.get('facility')?.setValue('');
      if (localStorage.getItem('suFcltyId') != null && location.href.includes('setup-fclty-smry')) {
        if (this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId')).length > 0)
          this.transForm.get('facility')?.setValue(this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName),
            this.str = this.lstFacs.filter(f => f.storeId == localStorage.getItem('suFcltyId'))[0]?.storeName, this.isSUFclty = true,
            this.slctdStrId = localStorage.getItem('suFcltyId'), this.LoadMatsbyStoreId(this.slctdStrId);
        if (txnName !== 'Transfers')
          this.GetLnkdStores(this.slctdStrId, val != '' && val === '2' ? 'v' : 'c');
      }
      this.GetMatrls().controls.length = 0, this.transForm.get('linked_store_id')?.setValue(''),
        this.transForm.get('linked_store_id')?.setValidators(null), this.lstMats = [];
      if (val != '') {
        this.transForm.get('transType')?.setValue(val); //, this.transForm.get('transType')?.disable();
        if (val == '5')
          this.transForm.get('linked_store_id')?.setValidators([Validators.required]);
      }
      else
        this.transForm.get('transType')?.enable();
      this.transForm.get('linked_store_id')?.updateValueAndValidity(); // this.transForm.get('transactualdate')?.updateValueAndValidity(),
    }
  }

  onChngTT1() {
    let val = this.transForm.get('transType')?.value;
    if (this.lstFacs.length == 1)
      this.GetLnkdStores(this.slctdStrId, val != '' && val === '2' ? 'v' : 'c'), this.LoadMatsbyStoreId(this.slctdStrId);
    else
      this.slctdStrId = null, this.storeID = '', this.transForm.get('facility')?.setValue('');
    this.GetMatrls().controls.length = 0, this.transForm.get('transactualdate')?.setValue(''),
      this.transForm.get('linked_store_id')?.setValue(''), // this.transForm.get('transactualdate')?.setValidators(null),
      this.transForm.get('linked_store_id')?.setValidators(null), this.lstMats = [];
    if (this.str == '' || val != '') {
      this.transForm.get('transType')?.setValue(val); //, this.transForm.get('transType')?.disable();
      // if (val == '1' || val == '2')
      //   this.transForm.get('transactualdate')?.setValidators([Validators.required]);
      if (val == '5')
        this.transForm.get('linked_store_id')?.setValidators([Validators.required]);
    }
    else
      this.transForm.get('transType')?.setValue(''), this.transForm.get('transType')?.enable();
    this.transForm.get('linked_store_id')?.updateValueAndValidity(); // this.transForm.get('transactualdate')?.updateValueAndValidity(),
  }

  LoadMatsbyStoreId(sId: number) {
    if (sId) {
      this.lstMats = [];
      if (this.transForm.get('transType')?.value != 5) {
        this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMatbyStoreId.
          replace('sss', `${sId}`).replace('ttt', this.transForm.get('transType')?.value), null).then(() => {
            if (this.result) {
              let res = this.result;
              if (this.result.status == 'OK') {
                if (this.result.data)
                  this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
                else
                  this.glblSvc.onTstr('i', this.result.message);
              }
              else
                this.glblSvc.onTstr('e', res.message);
            }
          });
      }
    }
  }

  LoadMatsbyRecvingStoreId() {
    this.lstMats = [], this.lstAllMats = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invAvblMats.
      replace('sss', this.slctdStrId).replace('rrr', this.rStore).replace(environment.noPgLen, ''), 0).then(() => {
        if (this.result) {
          let res = this.result;
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats, this.rStore = '';
            else
              this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', res.message);
        }
      });
  }

  slctdMat(event: any, evntType: string) {
    let matName = (evntType == 'chng' ? event.target.value : event.value);
    if (matName != '') {
      if (this.lstAllMats.filter(f => f.productName == matName).length > 0) {
        let avlbStk = this.lstAllMats.filter(f => f.productName == matName)[0].availableStock,
          isBatch = this.lstAllMats.filter(f => f.productName == matName)[0].batchManagement,
          ttlStk = this.lstAllMats.filter(f => f.productName == matName)[0].totalStock,
          alctdStk = this.lstAllMats.filter(f => f.productName == matName)[0].allocatedStock,
          matId = this.lstAllMats.filter(f => f.productName == matName)[0].productId,
          invMin = this.lstAllMats.filter(f => f.productName == matName)[0].invMin,
          invMax = this.lstAllMats.filter(f => f.productName == matName)[0].invMax,
          stockIndicator = this.lstAllMats.filter(f => f.productName == matName)[0].stockIndicator,
          hndlUnit = this.lstAllMats.filter(f => f.productName == matName)[0].handlingQuantity,
          // bdgeId = this.lstAllMats.filter(f => f.productName == matName)[0].productBadgeId;
          bdgeId = this.appBdgs.filter(b => b.name == this.lstAllMats.filter(f => f.productName == matName)[0].productBadge)[0]?.id ?? null;
        this.lstMats = this.lstMats.filter(f => f.productName != matName), this.transForm.get('srchMtrl')?.setValue(''), this.GetMatrls().
          push(this.onAddFormCtrls(matName, avlbStk, isBatch, matId, ttlStk, alctdStk, invMin, invMax, stockIndicator, hndlUnit, bdgeId)),
          this.ApplyValdtns();
        if (!isBatch)
          this.onFocusQnty();
      }
    }
  }

  slctdPd(event: any, indx: number, batIndx: number, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == '' || evnt == undefined)
      this.producer_id = null;
    else {
      if (this.lstAllProducers.filter(f => f.name == evnt).length != 0)
        this.producer_id = this.lstAllProducers.filter(f => f.name == evnt)[0].id
      else
        this.producer_id = null;
    }
    if (this.producer_id != null && evnt != '' && evnt != undefined)
      this.GetListMB(indx).at(batIndx).get('mnm')?.setValue(evnt); //.at(table).get('mnm').value; // this.GetListMB(indx).get('mnm')?.setValue(this.producer_id);
  }

  GetMatrls(): FormArray {
    return this.transForm.get('products') as FormArray;
  }

  onAddFormCtrls(matName: string, avlbStk: any, batMngt: boolean = false, mId: any, ttlStk: any, alctdStk: any, invMin: any, invMax: any,
    stockIndicator: any, hndlUnit: any, bdgeId: any) {
    let valTT = this.transForm.get('transType')?.value, ctrl = (valTT == '1' ? environment.cnfgTrnsRsnType.issue : (valTT == '2' ?
      environment.cnfgTrnsRsnType.stk_in : (valTT == '3' ? environment.cnfgTrnsRsnType.stk_edit : (valTT == '4' ?
        environment.cnfgTrnsRsnType.discard : environment.cnfgTrnsRsnType.transfer)))), lstRsn: any[] = [];
    lstRsn = (valTT == '1' ? this.lstRsnsIssue : (valTT == '2' ? this.lstRsnsRcpt : (valTT == '3' ?
      this.lstRsnTSC : (valTT == '4' ? this.lstRsnDscrd : this.lstRsnVVM))));
    if (bdgeId != null) {
      if (this.lstAllRsns.filter(a => a.reasonType == ctrl && a.badgeId == bdgeId).length > 0)
        lstRsn = this.lstAllRsns.filter(a => a.reasonType == ctrl && a.badgeId == bdgeId)
    }
    if (valTT == '1' || valTT == '4' || valTT == '5')
      this.maxAvail = avlbStk
    if (!batMngt)
      return this.fb.group({
        product_id: mId, nameMat: matName, ts: ttlStk, reason_id: [this.rsnDefault], isExpnd: false,
        batchManagement: batMngt, stk: avlbStk, alctdStk: [alctdStk], invMin: invMin, invMax: invMax, stockIndicator: stockIndicator,
        quantity: [''], material_status: [''], handlingQuantity: [hndlUnit], isInvalidPttrn: false, rsnsLst: [lstRsn], resnOthrs: null
      });
    else
      return this.fb.group({
        product_id: mId, nameMat: matName, ts: ttlStk, stk: avlbStk, isExpnd: false, mbList: this.fb.array([]),
        reason_id: [this.rsnDefault], batchManagement: batMngt, alctdStk: alctdStk, isEdit: true, invMin: invMin, invMax: invMax,
        stockIndicator: stockIndicator, handlingQuantity: [hndlUnit], isInvalidPttrn: false, rsnsLst: [lstRsn], resnOthrs: null
      });
  }

  onEditQty(evnt: any, matItem: any, batItem: any) {
    let bItem: any = batItem.getRawValue(), qntyVal = bItem.quantity != '' ? +bItem.quantity : null;
    if (evnt.target.value != '' && this.isStsMndtry)
      batItem.get('material_status')?.setValidators([Validators.required]);
    else
      batItem.get('material_status')?.setValidators(null);
    batItem.get('material_status')?.updateValueAndValidity(), batItem.get('isInvalidPttrn')?.setValue(false);
    if (bItem.handlingQuantity != null && qntyVal != null)
      batItem.get('isInvalidPttrn')?.setValue(qntyVal % bItem.handlingQuantity != 0);
  }

  onEditMatQty(evnt: any, matItem: any) {
    let item: any = matItem.getRawValue(), qntyVal = item.quantity != '' ? +item.quantity : null;
    if (evnt.target.value != '' && this.isStsMndtry)
      matItem.get('material_status')?.setValidators([Validators.required]);
    else
      matItem.get('material_status')?.setValidators(null);
    matItem.get('material_status')?.updateValueAndValidity(), matItem.get('isInvalidPttrn')?.setValue(false);
    if (item.handlingQuantity != null && qntyVal != null)
      matItem.get('isInvalidPttrn')?.setValue(qntyVal % item.handlingQuantity != 0);
  }

  ApplyValdtns(isBtch = false) {
    let valTT = this.transForm.get('transType')?.value, minVal = 1;
    this.GetMatrls().controls.forEach((e: any) => {
      if (e.get('handlingQuantity')?.value != null)
        minVal = +e.get('handlingQuantity')?.value;
      if (this.isRsnMndtry)
        e.get('reason_id')?.setValidators([Validators.required])
      else {
        let matBdgeDtls = this.lstAllMats.filter(f => f.productId == e?.getRawValue()?.product_id);
        // if (this.lstAllMats.filter(f => f.badgeId == (this.lstAllMats.filter(f => f.productId == e?.product_id)[0]?.productBadgeId)[0]?.isReasonMandatory))
        if (matBdgeDtls.length > 0) {
          if (this.lstAllRsns.filter(f => f.badgeId == matBdgeDtls[0]?.productBadgeId)[0]?.isReasonMandatory)
            e.get('reason_id')?.setValidators([Validators.required])
          else
            e.get('reason_id')?.setValidators(null)
        }
        else
          e.get('reason_id')?.setValidators(null)
      }
      if (!e.get('batchManagement')?.value) { // for non-batches
        let val = valTT == '3' ? e.get('alctdStk')?.value : e.get('stk')?.value;
        if (valTT != '2') {
          if (e.get('stk')?.value > 0) {
            e.get('quantity')?.setValidators([Validators.required, Validators.min(valTT == '3' ? val : minVal),
            Validators.minLength(valTT == '3' ? `${val}`.length : 1), Validators.max(valTT == '3' ? 99999999 : val),
            Validators.maxLength(valTT == '3' ? 8 : `${val}`.length)]); // , Validators.pattern(environment.patterns.numericsby5)
          }
          if (valTT == '3' && e.get('stk')?.value == 0)
            e.get('quantity')?.setValidators([Validators.required, Validators.min(minVal), Validators.minLength(`${val}`.length),
            Validators.max(99999999), Validators.maxLength(8)]); // , Validators.pattern(environment.patterns.numericsby5)
        }
        else
          e.get('quantity')?.setValidators([Validators.required, Validators.min(minVal), Validators.minLength(1)]); // Validators.pattern(environment.patterns.numericsby5)
        e.get('quantity')?.updateValueAndValidity();
      }
      else { // for batches
        // if (e.controls?.mbList?.controls.length != 0) {
        //   e.controls?.mbList?.controls.forEach((b: any) => {
        //     if (this.isStsMndtry)
        //       b.get('material_status')?.setValidators([Validators.required])
        //     else
        //       b.get('material_status')?.setValidators(null)
        //     b.get('material_status')?.updateValueAndValidity();         
        //   }) 
        // }
        /* if (isBtch) {
          if (valTT != '2') {
            e.get('mbList').controls.forEach((mb: any) => {
              let stkVal = mb.get('isEdit')?.value ? mb.get('stk')?.value : mb.get('as')?.value,
                alcStkVal = mb.get('isEdit')?.value ? mb.get('alctdStk')?.value : mb.get('alctdStk')?.value,
                val = valTT == '3' ? alcStkVal : stkVal;
              if (+stkVal > 0) {
                mb.get('quantity')?.setValidators([Validators.min(valTT == '3' ? val : 5),
                  Validators.minLength(valTT == '3' ? `${val}`.length : 1), Validators.max(valTT == '3' ? 99999999 : val),
                  Validators.maxLength(valTT == '3' ? 8 : `${val}`.length), Validators.pattern(environment.patterns.numericsby5)]);
                }
            });
            if (valTT == '3' && e.get('stk')?.value == 0) {
              e.get('mbList').controls.forEach((mb: any) => {
                let alcStkVal = mb.get('isEdit')?.value ? mb.get('alctdStk')?.value : mb.get('alctdStk')?.value;
                mb.get('quantity')?.setValidators([Validators.min(5), Validators.minLength(`${alcStkVal}`.length),
                  Validators.max(99999999), Validators.maxLength(8), Validators.pattern(environment.patterns.numericsby5)]);
              });
            }
          }
          else {
            e.get('mbList').controls.forEach((mb: any) => {
              mb.get('quantity')?.setValidators([Validators.min(5), Validators.minLength(1),
                Validators.pattern(environment.patterns.numericsby5)]);
            });
          }
          e.get('mbList').controls.forEach((mb: any) => { mb.get('quantity')?.updateValueAndValidity(); });
        } */
      }
      e.get('reason_id')?.updateValueAndValidity();
    });
  }

  onChngRsnTxt(evnt: any, indx: any) {
    this.GetMatrls().controls[indx - 1].get('resnOthrs')?.setValue(evnt.target.value);
  }

  slctReason(evnt: any, indx: any) {
    if (evnt.target.value == '354')
      this.GetMatrls().controls[indx - 1].get('resnOthrs')?.setValidators([Validators.required, Validators.maxLength(15)]);
    else
      this.GetMatrls().controls[indx - 1].get('resnOthrs')?.setValue('');
    this.GetMatrls().controls[indx - 1].get('resnOthrs')?.setValidators(null);

    this.GetMatrls().controls[indx - 1].get('resnOthrs')?.updateValueAndValidity();

  }

  onFocusQnty(isEntryBatch: boolean = false) {
    let matIndx = this.GetMatrls().controls.length;
    if (matIndx > 0) {
      if (isEntryBatch) {
        this.GetMatrls().at(0)?.get('mbList')?.value.forEach((e: any, i: number) => {
          if (i == 0)
            setTimeout(() => { this.setElmntFocus(`wbQnty${matIndx - 1}0`); }, 100);
        });
      }
      else
        setTimeout(() => { this.setElmntFocus(`nbQnty${matIndx - 1}`); }, 100);
    }
  }

  onChngBM(matIndx: number, batIndx: number, isEdit: boolean = true) {
    if (isEdit) {
      let btchID = this.GetListMB(matIndx).controls[batIndx].get('bid')?.value, mnfr = this.producer_id != null ? `${this.producer_id}` : '',
        matName = this.GetMatrls().at(matIndx).get('product_id')?.value;
      if (btchID != '' && btchID != null && btchID != undefined && mnfr != '' && mnfr != null && mnfr != undefined && matName != '' &&
        matName != null && matName != undefined) {
        this.FetchData(environment.apiCalls.get, environment.apiPaths.iCatBtch, environment.apiMthds.getBtchWiseDtls.replace('ppp', mnfr).
          replace('bbb', `${btchID}`.toUpperCase()).replace('mmm', matName), null).then(() => {
            if (this.result) {
              if (this.result.data) {
                if (this.result.data?.manufacturedDate != null && this.result.data?.expiryDate != null)
                  this.GetListMB(matIndx).controls[batIndx].get('md')?.setValue(this.glblSvc.setDtFrmt(this.result.data.manufacturedDate,
                    this.dtF.dmy2)), this.GetListMB(matIndx).controls[batIndx].get('e')?.setValue(this.glblSvc.setDtFrmt(this.result.data.
                      expiryDate, this.dtF.dmy2)), this.GetListMB(matIndx).controls[batIndx].get('isDMD')?.setValue(true),
                    this.GetListMB(matIndx).controls[batIndx].get('isDED')?.setValue(true);
                else
                  this.GetListMB(matIndx).controls[batIndx].get('md')?.setValue(''), this.GetListMB(matIndx).controls[batIndx].get('e')?.
                    setValue(''), this.GetListMB(matIndx).controls[batIndx].get('isDMD')?.setValue(false),
                    this.GetListMB(matIndx).controls[batIndx].get('isDED')?.setValue(false);
              }
              else
                this.glblSvc.onTstr('w', this.result.message)
            }
          });
      }
    }
  }

  expiredDateCheck(resultDate: any) {
    let toDayDate = Date.parse(this.glblSvc.setDtFrmt(this.tdDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(resultDate, this.dtF.ymd));
    if (exDate < toDayDate)
      return true;
    else
      return false;
  }

  onCreateListMB(indx: number = 0): FormGroup {
    let rsnVal = this.transForm.get('transType')?.value == '3' ? '' : 'NA', valTT = this.transForm.get('transType')?.value,
      hndlUnit = this.GetMatrls().at(indx)?.get('handlingQuantity')?.value, minVal = hndlUnit != null ? hndlUnit : 1;
    if (valTT == '1' || valTT == '4' || valTT == '5')
      this.maxBAvail = this.GetMatrls().at(indx).get('stk')?.value;
    else
      this.maxBAvail = 99999999;
    return this.fb.group({
      bid: ['', [Validators.required, Validators.maxLength(20)]], catalog_id: [0], e: ['', Validators.required],
      mnm: ['', Validators.required], mid: [this.GetMatrls().at(0).get('mId')?.value], mst: ['VVM Usable'], md: ['', [Validators.required]],
      reason_id: [rsnVal], isEdit: true, material_status: [''], handlingQuantity: [hndlUnit], isInvalidPttrn: false, isDMD: true, isDED: true,
      // ts: [this.GetMatrls().at(indx).get('ts')?.value], as: [this.GetMatrls().at(indx).get('stk')?.value], alctdStk: this.GetMatrls().at(indx).get('alctdStk')?.value
      ts: 0, as: 0, alctdStk: 0, quantity: ['', [Validators.min(minVal), Validators.minLength(`${minVal}`.length)]]
    });
    /* quantity: ['', [Validators.min(valTT == '3' ? this.GetMatrls().at(indx).get('alctdStk')?.value : minVal), 
      Validators.minLength(valTT == '3' ? `${this.GetMatrls().at(indx).get('alctdStk')?.value}`.length : 1), 
      Validators.max(this.maxBAvail), Validators.maxLength(`${this.maxBAvail}`.length)]] */
    // , Validators.pattern(environment.patterns.numericsby5)
  }

  onAddBats(elmnt: any, indx: number, bIndex: number) {
    let valTT = this.transForm.get('transType')?.value, rsnVal = ((valTT == '3' || valTT == '5') ? '' : (valTT == '4' ? 'Expired' : 'NA')),
      hndlUnit = this.GetMatrls().at(indx)?.get('handlingQuantity')?.value, minVal = hndlUnit != null ? hndlUnit : 1;
    if (valTT == '1' || valTT == '4' || valTT == '5')
      this.maxBAvail = elmnt.available_stock;
    else
      this.maxBAvail = 99999999;
    return this.fb.group({
      bid: [elmnt.batch_no], ts: [elmnt.total_stock], material_status: [''], isEdit: [false], reason_id: [''],
      md: [(elmnt.manufactured_date != '' && elmnt.manufactured_date != null) ? this.glblSvc.setDtFrmt(`${elmnt.manufactured_date}`.split(' ')[0], this.dtF.dmy) : ''],
      e: [(elmnt.expiry_date != '' && elmnt.expiry_date != null) ? this.glblSvc.setDtFrmt(`${elmnt.expiry_date}`.split(' ')[0], this.dtF.dmy) : ''],
      catalog_id: [0], mnm: [elmnt.batch_producer_name], mid: [elmnt.producer_id], as: [elmnt.available_stock], alctdStk: [elmnt.allocated_stock],
      quantity: ['', [Validators.min(valTT == '3' ? elmnt.allocated_stock : minVal), Validators.minLength(valTT == '3' ?
        `${elmnt.allocated_stock}`.length : 1), Validators.max(this.maxBAvail), Validators.maxLength(`${this.maxBAvail}`.length)]],
      handlingQuantity: [hndlUnit], isInvalidPttrn: false
    }); // Validators.pattern(environment.patterns.numericsby5)
  }

  onDelMtrls(tabIndex: any, matName: any) {
    this.GetMatrls().removeAt(tabIndex), this.lstChsnMats = this.lstChsnMats.filter(f => f.nameMat != matName),
      this.lstMats.push(this.lstAllMats.filter(f => f.productName == matName)[0]), this.lstMats.sort((a, b) => a.productId - b.productId);
  }

  onBtchActvty(type: string, matIndx: number, batIndx: number) {
    if (type == 'a') {
      if (this.GetListMB(matIndx).length == 0)
        this.GetListMB(matIndx).push(this.onCreateListMB(matIndx));
      else {
        let mtCnt = 0;
        this.GetListMB(matIndx).controls.forEach((e: any) => {
          if ((e.controls.bid.value == '' || e.controls.mnm.value == '' || e.controls.e.value == '' || e.controls.quantity.value == '')
            && e.controls.isEdit.value == true) {
            this.glblSvc.onTstr('i', 'Kindly fill all mandatory fields to add a new record'), mtCnt++;
            return;
          }
        });
        if (mtCnt == 0)
          this.GetListMB(matIndx).push(this.onCreateListMB(matIndx));
      }
    }
    else
      this.GetListMB(matIndx).removeAt(batIndx);
  }

  GetListMB(tabIndex: number): FormArray {
    return this.GetMatrls().at(tabIndex).get('mbList') as FormArray;
  }

  onChngMD(matIndx: number, batIndx: number) {
    this.GetListMB(matIndx).controls[batIndx]?.get('e')?.setValue('');
  }

  onChkLL() {
    if (this.isReqLL == '1') {
      this.GetLatLon(), setTimeout(() => {
        if (this.usrLat != undefined && this.usrLon != undefined)
          this.onCreateTrans();
        else {
          setTimeout(() => {
            if (this.usrLat == undefined || this.usrLon == undefined)
              this.glblSvc.onTstr('i', 'Kindly provide Location access to Create Transaction..!');
          }, 2000);
        }
      }, 500);
    }
    else
      this.onCreateTrans();
  }

  onCreateTrans() {
    this.checkFormValidations(this.transForm), this.checkFormArrayValidations(this.transForm.controls['products']);
    let isFrstBtch = false, txnType = this.transForm.get('transType')?.value, noBtchQnty = 0, isBtchFild = true;
    this.GetMatrls().controls.forEach((e: any, i: number) => {
      let noAvlb = 0, btchCnt = 0;
      if (e?.get('batchManagement').value) {
        let qntyVal = 0, val = e?.get('mbList').controls[0]?.get('as')?.value ?? null;
        if (txnType === '1' || txnType === '5' && val != null) {
          if (Object.keys(e.controls).includes('mbList'))
            qntyVal = e?.get('mbList').controls[0]?.get('quantity').value != '' ? +e?.get('mbList').controls[0]?.get('quantity').value : qntyVal;
          if (+val > 0)
            isFrstBtch = i == 0 && qntyVal == 0;
        }
        noBtchQnty = 0, e?.get('mbList').controls.forEach((mb: any) => {
          btchCnt++;
          if (mb?.get('quantity').value != '') noBtchQnty++; if (mb?.get('as').value == 0) noAvlb++;
        });
        if (noBtchQnty == 0) {
          if (btchCnt > 0 && noAvlb > 0 && btchCnt == noAvlb && (txnType != '2' || txnType != '3')) {
            isBtchFild = false, this.glblSvc.onTstr('i', 'You are not supposed to submit as there is no Available stock, kindly remove & choose another.!');
            return;
          }
          else {
            isBtchFild = false, this.glblSvc.onTstr('w', `Kindly ${val != null ? 'fill' : 'add'} at least one ${val != null ? 'quantity in batches' : 'batch'}`);
            return;
          }
        }
      }
    });
    if (this.transForm.invalid || this.transForm.controls['products'].invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      if (isBtchFild) {
        if (isFrstBtch) {
          if (confirm(environment.cnfmMsg1))
            this.onTrnsCreate();
        }
        else
          this.onTrnsCreate();
      }
    }
  }

  onTrnsCreate() {
    let payLoad: any = {}, bMats: any[] = [], mats: any[] = [], frmCtrlsVal = this.transForm.getRawValue(), mpChk = 0, bpChk = 0,
      lnkStrDtls: any = this.storeID != '' ? this.lstLinkedStrs.filter(f => +f.mapped_store_id == +this.storeID)[0] : {}, slctStrDtls: any =
        this.lstAllMats.filter((f: any) => +f.storeId === +this.slctdStrId)[0], slctdStrLocName = JSON.parse(<string>sessionStorage.
          getItem('lgnUsrStrs')).filter((f: any) => +f.storeId == +this.slctdStrId)[0]?.location ?? null;
    frmCtrlsVal.products.filter((f: any) => f.batchManagement == true).forEach((e: any) => {
      e.mbList.forEach((b: any) => {
        if (b.isInvalidPttrn)
          bpChk++;
        if (b.quantity != '') {
          let prdcrId = this.lstAllProducers.filter(f => f.name == b.mnm)[0]?.id ?? null, valTT = frmCtrlsVal.transType,
          matDtls: any = this.lstAllMats.filter(f => +f.productId == +e.product_id)[0], rsnStsLst: any[] = (valTT == '1' ?
            this.lstRsnsIssue : (valTT == '2' ? this.lstRsnsRcpt : (valTT == '3' ? this.lstRsnTSC : (valTT == '4' ? this.lstRsnDscrd :
              this.lstRsnVVM)))), mtStsLst: any[] = valTT == '1' ? this.stIssue : (valTT == '2' ? this.stReceipt : (valTT == '3' ?
                this.stStkCnt : (valTT == '4' ? this.stDscrd : this.stTrnsfr)));
          /* if (environment.isLiveQA) {
            bMats.push({ batch_id: b.bid != '' ? `${b.bid}`.toUpperCase() : '', expiry_date: (b.e != null && b.e != '') ? this.glblSvc.
              setDtFrmt(b.e, this.dtF.ymd) + this.tmF.zeroTM : '', manufactured_date: (b.md != null && b.md != '') ? this.glblSvc.
              setDtFrmt(b.md, this.dtF.ymd) + this.tmF.zeroTM : '', producer_id: prdcrId, product_id: e.product_id, material_status:
              b.material_status != '' ? +b.material_status : null, quantity: +b.quantity, reason_id: e.reason_id != '' ? +e.reason_id : null,
            });
          }
          else { */
          bMats.push({
            batch_id: b.bid != '' ? `${b.bid}`.toUpperCase() : '', expiry_date: (b.e != null && b.e != '') ? this.glblSvc.
              setDtFrmt(b.e, this.dtF.ymd) + this.tmF.zeroTM : '', manufactured_date: (b.md != null && b.md != '') ? this.glblSvc.
                setDtFrmt(b.md, this.dtF.ymd) + this.tmF.zeroTM : '', producer_id: prdcrId, product_id: e.product_id, material_status:
              b.material_status != '' ? +b.material_status : null, quantity: +b.quantity, reason_id: e.reason_id.reasonId != '' ? +e.reason_id.reasonId : null, 
              // Added BY Ghouse 15-03-2023 for reason_name
              reason_name: e.reason_id.reasonName != '' ? e.reason_id.reasonName : null,
            // new keys - 7
            is_batch_enabled: true, producer_name: b.mnm, product_name: matDtls?.productName ?? null,
            reason_name_res: (+e.reason_id.reasonId == 354 && e.reason_id.reasonId != '') ? e.resnOthrs : rsnStsLst.filter(f => +f.reasonId == +e.reason_id)[0]?.reasonName,
            // reason_name: e.reason_id != '' ? rsnStsLst.filter(f => +f.reasonId == +e.reason_id)[0]?.reasonName : null,
            product_badge_id: matDtls?.productBadgeId ?? null, product_badge_name: matDtls?.productBadge ?? null,
            material_status_name: b.material_status != '' ? mtStsLst.filter(f => +f.id == +b.material_status)[0]?.name : null,
          });
          // }
        }
      });
    }), frmCtrlsVal.products.filter((f: any) => f.batchManagement == false).forEach((e: any) => {
        console.log('e',JSON.stringify(e))
      if (e.isInvalidPttrn) mpChk++;
      let valTT = frmCtrlsVal.transType, matDtls: any = this.lstAllMats.filter(f => +f.productId == +e.product_id)[0],
        rsnStsLst: any[] = (valTT == '1' ? this.lstRsnsIssue : (valTT == '2' ? this.lstRsnsRcpt : (valTT == '3' ? this.lstRsnTSC :
          (valTT == '4' ? this.lstRsnDscrd : this.lstRsnVVM)))), mtStsLst: any[] = valTT == '1' ? this.stIssue : (valTT == '2' ?
            this.stReceipt : (valTT == '3' ? this.stStkCnt : (valTT == '4' ? this.stDscrd : this.stTrnsfr)));
      if (e.quantity != '') {
        /* if (environment.isLiveQA) {
          mats.push({ product_id: e.product_id, quantity: parseInt(e.quantity), reason_id: e.reason_id != '' ? +e.reason_id : null,
            material_status: e.material_status != '' ? +e.material_status : null });
        }
        else { */
        mats.push({
          product_id: e.product_id, quantity: parseInt(e.quantity), 
          reason_id: e.reason_id.reasonId != '' ? +e.reason_id.reasonId : null,
          // Added BY Ghouse 15-03-2023 for reason_name
          reason_name: e.reason_id.reasonName != '' ? e.reason_id.reasonName : null,
          material_status: e.material_status != '' ? +e.material_status : null,
          // new keys - 5
          material_status_name: e.material_status != '' ? mtStsLst.filter(f => +f.id == +e.material_status)[0]?.name : null, product_badge_id:
            matDtls?.productBadgeId ?? null, product_badge_name: matDtls?.productBadge ?? null, product_name: matDtls?.productName ?? null,
          reason_name_res: e.reason_id.reasonId != '' ? rsnStsLst.filter(f => +f.reasonId == +e.reason_id)[0]?.reasonName : null,
        });
        // }
      }
    });
    /* if (environment.isLiveQA)
      payLoad = { user_id: this.lgnUsrData?.userId, batch_products: bMats, linked_store_id: this.storeID != '' ? +this.storeID : null, source: 2,
        txn_actual_date: frmCtrlsVal.transactualdate != '' ? this.glblSvc.setDtFrmt(frmCtrlsVal.transactualdate, this.dtF.ymd, this.tmF.hmsa) :
        null, store_id: this.slctdStrId, txn_type_id: +frmCtrlsVal.transType, pranth_id: this.lgnUsrData?.pranthId ?? null, products: mats };
    else */
    //console.log('selectedTypeReason',this.selectedTypeReason)
    payLoad = {
      user_id: this.usrLgnDtls?.userId, batch_products: bMats, linked_store_id: this.storeID != '' ? +this.storeID : null, source: 2,
      txn_actual_date: frmCtrlsVal.transactualdate != '' ? this.glblSvc.setDtFrmt(frmCtrlsVal.transactualdate, this.dtF.ymd, this.tmF.hmsa) :
        null, store_id: this.slctdStrId, txn_type_id: +frmCtrlsVal.transType, pranth_id: slctStrDtls?.pranthId ?? null, products: mats,
      // new keys - 12
      country_id: slctStrDtls?.countryId ?? null, district_id: slctStrDtls?.districtId ?? null, pranth_name: slctStrDtls?.pranthName ?? null,
      linked_store_location: lnkStrDtls?.mapped_store_location ?? null, linked_store_name: lnkStrDtls?.mapped_store_name ?? null,
      state_id: slctStrDtls?.stateId ?? null, store_badge_id: slctStrDtls?.storeBadgeId ?? null, store_badge_name: slctStrDtls?.storeBadgeName
        ?? null, store_location: slctdStrLocName, user_name: `${this.usrLgnDtls?.firstName} ${this.usrLgnDtls?.lastName}`,
      store_name: slctStrDtls?.storeName ?? null, txn_type_name: this.lstTxnTyps.filter(f => +f.id == +frmCtrlsVal.transType)[0].name,
      latitude: (this.usrLat != '' && this.usrLat != null && this.usrLat != undefined) ? +this.usrLat : null,
      longitude: (this.usrLon != '' && this.usrLon != null && this.usrLon != undefined) ? +this.usrLon : null
    };
   
    if (Object.keys(payLoad).length > 0) {
      if (mpChk == 0 && bpChk == 0) {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.trans2, // environment.isLiveQA ? environment.apiPaths.trans : environment.apiPaths.trans2,
          environment.apiMthds.saveRupdate, payLoad).then(() => {
            let res = this.result;
            if (this.result) {
              if (this.result.status == 'OK') {
                localStorage.setItem('transCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message), this.isSbmtd = false,
                  this.isResetClk = true, this.onReset();
                if (this.paramsTab == 'tc')
                  this.route.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'tl', type: 'list' } }); // setTimeout(() => { window.location.reload(); }, 1000);
                else
                  localStorage.setItem('txnLst', '1'), this.route.navigate(['home/trans'], { queryParams: { tab: 'list', type: 'tL' } });
                this.glblSvc.sbjctTabsPage.next('trans-create');
              } // 'list' 'trans-list' setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', res.message);
          });
      }
      else
        this.glblSvc.onTstr('w', 'Please enter Quantity with respect to multiples of handling unit');
    }
  }

  onClkEBB(indx: number) {
    let isExpnd: any = this.GetMatrls().at(indx).get('isExpnd'), product_id: any = this.GetMatrls().at(indx).get('product_id')?.value,
      valTT = this.transForm.get('transType')?.value;
    isExpnd.value = !isExpnd.value;
    if (isExpnd.value) { // && valTT != '2'
      this.GetListMB(indx).clear(), this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
        environment.apiMthds.getBatchbyMatId.replace('kkk', this.slctdStrId).replace('mmm', product_id), null).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.data != null) {
                if (valTT == '4')
                  this.result?.data?.forEach((e: any, bIndex: number) => { this.GetListMB(indx).push(this.onAddBats(e, indx, bIndex)); });
                else
                  this.result?.data?.filter((f: any) => f.expired == false).forEach((e: any, bIndex: number) => {
                    this.GetListMB(indx).push(this.onAddBats(e, indx, bIndex));
                  }), this.ApplyValdtns(true), this.onFocusQnty(true);
              }
              else
                this.glblSvc.onTstr('i', this.result.message);
            }
          }
        });
    }
  }

  onClkBtch(type: string, indx: number) {
    if (type == 'v') {
      const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
      modalRef.componentInstance.title = this.GetMatrls().at(indx).get('nameMat')?.value;
      modalRef.componentInstance.kioskId = this.slctdStrId;
      modalRef.componentInstance.prdctId = this.GetMatrls().at(indx).get('product_id')?.value;
      modalRef.componentInstance.tab = 'b';
      modalRef.componentInstance.isShowAll = false;
      modalRef.result.then(result => { }, (r) => { console.log(r) });
    }
    else
      this.GetListMB(indx).controls.forEach(e => { if (e.get('quantity')?.value != '') e.get('quantity')?.setValue(0); });
  }

  onReset() {
    if (!location.href.includes('trans?tab=new') || this.isResetClk) {
      this.transForm.reset(), this.transForm.get('transType')?.setValue(''), this.transForm.get('matCatgFilt')?.setValue(''), this.str = '',
        this.GetMatrls().controls.length = 0, this.slctdStrId = null, this.storeID = '', this.isSbmtd = false, this.actTxnFlag = false;
      if (this.lstFacs.length == 1)
        this.transForm.get('facility')?.setValue(this.lstFacs[0].storeName), this.slctdStrId = this.lstFacs[0].storeId,
          this.str = this.lstFacs[0].storeName;
    }
  }

}
